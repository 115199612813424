import React from 'react';
import { Tooltip } from '@material-ui/core';

interface SpanClickViewProps {
    tag: string;
    item: JSX.Element,
    tooltip?: string;
    onClick: (tag: string) => void;
    disabled?: boolean;
    warning?: boolean;
  }
  const SpanClickView : React.FC<SpanClickViewProps> = (props) => {
    var span = null;
    var borderBottomx = props.warning ? "2px solid red" : "";
    if (props.disabled) {
      span = (<span key={`SpanClickView_${props.tag}`}>{props.item}</span>);
    } else {
      span = (<span key={`SpanClickView_${props.tag}`} style={{cursor: "pointer", marginRight:"2px", borderBottom: `${borderBottomx}` }} onClick={e => props.onClick(props.tag)}>{props.item}</span>);
    }
    if (props.tooltip && props.tooltip.length > 0) {
      return (
        <Tooltip key={`SpanClickView_Tooltip_${props.tag}`} title={props.tooltip} >
          {span}
        </Tooltip>
      );
    }
    return (
      <>
          {span}
      </>
    );
  }
  export default SpanClickView;