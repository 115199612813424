import React from 'react';
import Box from '@material-ui/core/Box';
import { Link } from '@material-ui/core';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';

interface Fixture {
  id: number;
  name: string;
  startingAt: Date;
  status: string;
  leagueId: number;
  leagueName: string;
}
interface FixtureLabelProps {
  fixture: any;
  onClick?: (fixture : any) => void;
}

const FixtureLabel: React.FC<FixtureLabelProps> = (props) => {

  const onFixtureClick = () => {
    if (props.onClick){
      props.onClick(props.fixture);
    }
  };
  var fixture = props.fixture;
  var fixtureName : any = fixture.name;
  var leagueName = fixture.leagueTitle ? fixture.leagueTitle : fixture.leagueName;
  if (props.onClick) {
    fixtureName = (<Link onClick={e => onFixtureClick()}>{fixture.name}</Link>)
  }

  return (
    <div>
      {fixtureName}
      <Box fontSize={11}>{CreateRoundUtil.toGameDateString(fixture.startingAt)} | {leagueName} | {fixture.status}</Box>
    </div>
  );
};
export default FixtureLabel;
