import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { IconButton, Link, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DataTable, { CreateDataRowContext, DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { friendlyName } from '../common/helpers/RoundHelper';
import Ticker2Util from '../Ticker2/Ticker2Util';
import { formatToCurrency } from '../common/helpers/CurrencyHelpers';
import DeleteIcon from '@material-ui/icons/Delete';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Player } from './AgentModels';

interface EditAgentPlayerTableProps {
    items: Player[];
    onDelete? : (item : Player) => void;
}
interface EditAgentPlayerTableActionProps {
  item: Player;
  onDelete? : (item : Player) => void;
}

const EditAgentPlayerTableAction: React.FC<EditAgentPlayerTableActionProps> = (props) => {
  const onDelete = () => {
    if (props.onDelete){
      props.onDelete(props.item);
      return;
  }
};
return (
    <IconButton aria-label="delete" color="secondary" size="small" onClick={(e: any) => onDelete()} >
    <DeleteIcon />
  </IconButton>

  );
}
const EditAgentPlayerRow: React.FC<EditAgentPlayerTableActionProps> = (props) => {
  const onDelete = () => {
      if (props.onDelete){
        props.onDelete(props.item);
        return;
    }
  };
  var row = props.item;
  return (
    <TableRow key={row.id} >
      <TableCell component="th" scope="row">
        <IconButton aria-label="delete" color="secondary" size="small" onClick={(e: any) => onDelete()} >
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        {row.id}
      </TableCell>
      <TableCell>
        {row.operatorPlayerId}
      </TableCell>
      <TableCell>
        {row.notes}
      </TableCell>
      <TableCell>
        {row.operatorId}
      </TableCell>
    </TableRow>

  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const EditAgentPlayerTable: React.FC<EditAgentPlayerTableProps> = (props) => {
    //const classes = useDataTableStyles();
    const classes = useStyles();
    // const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<Player>[]>([]);
    // const [items, setItems] = useState<Player[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    
    // const checkItemsChanged = () : void => {
    //   var itemsChanged = false;
    //   if (props.items.length !== items.length) {
    //     itemsChanged = true;
    //   } else if (props.items.length > 0) {
    //     for (var i = 0; i < props.items.length; i++) {
    //       if (props.items[i].id !== items[i].id) {
    //         itemsChanged = true;
    //         break;
    //       }
    //     }
    //   }
    //   if (itemsChanged) {
    //     setItems(props.items);
    //   }
    // };
    // const renderActionValue = (item: Player) : JSX.Element => {
    //   return (
    //     <IconButton aria-label="delete" color="secondary" size="small" onClick={(e: any) => onDelete(item)} >
    //     <DeleteIcon />
    //   </IconButton>

    //   );
    //   // return (
    //   //   <EditAgentPlayerTableAction item={item} onDelete={onDelete} />
    //   // );
    // };

    const refreshColumns = () => {
      var cols: DataTableColumn<Player>[] = [
        { id: undefined, numeric: false, disablePadding: false, label: 'Action', width:100  },
        { id: 'id', numeric: false, disablePadding: true, label: "Player", filterEnabled: true },
        { id: 'operatorPlayerId', numeric: false, disablePadding: false, label: 'External id', filterEnabled: true },
        { id: 'notes', numeric: false, disablePadding: false, label: 'Notes', filterEnabled: true },
        { id: 'operatorId', numeric: false, disablePadding: false, label: 'Operator', filterEnabled: false },
      ];
      setColumns(cols);
    }
    const onDelete = (item : Player) => {
      if (props.onDelete){
          props.onDelete(item);
          return;
      }
      alert(`Delete not implemented [PlayerId:${item.id}]`);
    };
  //   const onDeleteById = (playerId: string) => {
  //     if (props.onDelete){
  //       var item = props.items.find(it => it.id === playerId);
  //       if (!item) {
  //         item = items.find(it => it.id === playerId);
  //       }
  //       if (item) {
  //         props.onDelete(item);
  //       }
  //       return;
  //   }
  //   alert(`Delete not implemented [PlayerId:${playerId}]`);
  // };

    const createRow = (item: Player, context: CreateDataRowContext<Player>) : JSX.Element => {
      return (
        <EditAgentPlayerRow item={item} onDelete={onDelete} />

      );
      // return (
      //   <EditAgentPlayerTableAction item={item} onDelete={onDelete} />
      // );
    };

  //checkItemsChanged();

  return (
    <DataTable columns={columns} items={props.items} getId={it => it.id} orderBy='id' order='asc' resultFilterEnabled={false} size='xsmall' rowsPerPage={25} createRow={createRow}  />
    );
//   return (
//   <TableContainer component={Paper}>
//   <Table className={classes.table} aria-label="simple table">
//     <TableHead>
//       <TableRow>
//         <TableCell width={100}>Action</TableCell>
//         <TableCell>Player</TableCell>
//         <TableCell>External id</TableCell>
//         <TableCell>Notes</TableCell>
//         <TableCell>Operator</TableCell>
//       </TableRow>
//     </TableHead>
//     <TableBody>
//       {props.items.map((item) => (
//           <EditAgentPlayerRow item={item} onDelete={onDelete} />
//       ))}
//     </TableBody>
//   </Table>
// </TableContainer>
//  );
};
  
export default EditAgentPlayerTable;
