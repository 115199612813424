import { max } from "moment";
import { getList, getListWithId, getObject } from "../api/dataProvider";
import { Draw, Fixture, FixtureResult, Outcome, RoSettlementInput, Round, RoundOverviewFixture, RoundOverviewItem, RoundCurrency, TickerItem, TeamDetail } from "./RoundOverviewModels";
import { RoundType } from '../models/RoundType';
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { GenericResponse, LeagueListItem } from "../CreateRound/CreateRoundModels";
import { green, red } from "@material-ui/core/colors";
import ApiNgUtil from "../api/ApiNgUtil";
import { ListResponse } from "../api/ApiNgModels";
import { formatToCurrency } from "../common/helpers/CurrencyHelpers";
import { friendlyName } from "../common/helpers/RoundHelper";
import { QueryParams } from "../models/CouponShare";
import InvokeApiMethods from "../ExternalApi/ExternalApiModels";

class RoundOverviewUtil {
    static DEV_ENVIRONMENT = "dev";
    static DEMO_ENVIRONMENT = "demo";
    static PROD_ENVIRONMENT = "prod";
    static UNKNOWN_ENVIRONMENT = "unknown";
    static ACTIVE_STATUS = "ACTIVE";
    static PENDING_STATUS = "PENDING";
    static FINISHED_STATUS = "FINISHED";
    static PUBLISHED_STATUS = "PUBLISHED";
    static enabledStatuses = ["ACTIVE", "PENDING", "PAUSED"];
    static enabledDrawStatuses = [RoundOverviewUtil.PENDING_STATUS, RoundOverviewUtil.PUBLISHED_STATUS];
    static ONE_OUTCOME_SIGN = "ONE";
    static X_OUTCOME_SIGN = "X";
    static TWO_OUTCOME_SIGN = "TWO";
    static UNKNOWN_OUTCOME_SIGN = "UNKNOWN";
    static OUTCOME_SIGNS = [RoundOverviewUtil.ONE_OUTCOME_SIGN, RoundOverviewUtil.X_OUTCOME_SIGN, RoundOverviewUtil.TWO_OUTCOME_SIGN];
    static ONE_OUTCOME_FRIENDLY_SIGN = "1";
    static X_OUTCOME_FRIENDLY_SIGN = "X";
    static TWO_OUTCOME_FRIENDLY_SIGN = "2";
    static OUTCOME_FRIENDLY_SIGNS = [RoundOverviewUtil.ONE_OUTCOME_FRIENDLY_SIGN, RoundOverviewUtil.X_OUTCOME_FRIENDLY_SIGN, RoundOverviewUtil.TWO_OUTCOME_FRIENDLY_SIGN];
    static VALID_COLOR = green[500];
    static INVALID_COLOR = red[500];
    static PLAYING_STATUS = "PLAYING";
    static ABORTED_STATUS = "ABORTED";
    static fixtureStatuses = [RoundOverviewUtil.PENDING_STATUS, RoundOverviewUtil.PLAYING_STATUS, RoundOverviewUtil.FINISHED_STATUS, RoundOverviewUtil.ABORTED_STATUS];
    static AUTOMATIC_UPDATE_MODE = "AUTOMATIC";
    static MANUAL_UPDATE_MODE = "MANUAL";
    static updateModes = [RoundOverviewUtil.AUTOMATIC_UPDATE_MODE, RoundOverviewUtil.MANUAL_UPDATE_MODE];
    static CURRENCY_EUR = "EUR";
    static isEnabledStatus(status: string) : boolean {
        if (!status || status.length <= 0){
            return false;
        }
        var index = this.enabledStatuses.indexOf(status);
        if (index < 0){
            return false;
        }
        return true;
    }
    static isEnabledDrawStatus(status: string | null | undefined) : boolean {
        if (!status || status.length <= 0){
            return false;
        }
        var index = this.enabledDrawStatuses.indexOf(status);
        if (index < 0){
            return false;
        }
        return true;
    }
    static toRoundOverviewItems(rounds: Round[], roundTypes: RoundType[]) : RoundOverviewItem[] {
        var roundOverviews: RoundOverviewItem[] = [];
        rounds.forEach((round: Round) => {
            var roundType = roundTypes.find(it => it.id === round.roundTypeId);
            var roundTypeName = (roundType) ? roundType.parameters.name : "";
            var turnover : number | undefined = undefined;
            if (round.balances && round.balances.POOL && round.balances.POOL.length > 0){
                turnover = parseFloat(round.balances.POOL);
                if (isNaN(turnover)){
                    turnover = undefined;
                }
            }
            var roundOverview : RoundOverviewItem = {
                id: parseInt(round.id),
                roundTypeId: parseInt(round.roundTypeId),
                roundTypeName: roundTypeName,
                deadline: round.openTo,
                status: round.status,
                betCount: undefined,
                turnover: turnover,
                extBetCount: undefined,
                extTurnover: undefined,
                extPL: undefined,
                visible: round.visibleToPlayers,
                fixtures: null      
            }
            roundOverviews.push(roundOverview);
        });
        return roundOverviews;
    }
    // static async getRoundOverviewItems(perPage : number = 100, roundTypes : RoundType[]) : Promise<RoundOverviewItems[]> {
    //     var resp = await getList('leagues', {
    //         pagination: { page: 0, perPage: perPage },
    //         filter: {},
    //         sort: { field: "id", order: "desc" }
    //     });
    //     return CreateRoundUtil.toRoundOverviewItems(resp.data, roundTypes);
    // }
    static async getRound(roundId : string) : Promise<GenericResponse<Round>> {
        const METHOD_NAME = "RoundOverviewUtil.getRound";
        try {
            if (!roundId || roundId === "" || roundId === "0"){
                return CreateRoundUtil.createGenericResponse<Round>(false, "RoundId was not specified.", null);
            }
            var resp : any = await getObject('rounds/' + roundId);
            var round : Round = resp.data;
            return CreateRoundUtil.createGenericResponse<Round>(true, "", round);
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<Round>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async getFixturesById(fixtureIds : number[]) : Promise<Fixture[]> {
        var resp = await getListWithId("fixtures", fixtureIds);
        var fixtures: Fixture[] = resp.data;
        return fixtures;
    }
    static async fixMissingLeagues(roFixtures : RoundOverviewFixture[], leagues : LeagueListItem[], leagueIds : number[]) : Promise<number>{
        if (leagueIds.length <= 0) {
            return 0;
        }
        var leagueResp = await getListWithId("leagues", leagueIds);
        var leagues1: any = leagueResp.data;
        if (leagues1 === null) {
            return 0;
        }

        var leagues2: LeagueListItem[] = CreateRoundUtil.toLeagueListItems(leagues1);
        if (leagues2.length <= 0) {
            return 0;
        }
        CreateRoundUtil.addLeagues(leagues, leagues2, true);
        var nonLeagueFixtures = roFixtures.filter((f) => f.leagueId && f.leagueId.length > 0 && (f.leagueName === null || f.leagueName === "" || f.leagueName.startsWith("Unknown")));
        nonLeagueFixtures.sort((a, b) => CreateRoundUtil.compareString(a.leagueId, b.leagueId));
        var currentLeagueId: string = "";
        var currentLeague: LeagueListItem | null | undefined = null;
        var fixedCount = 0;
        nonLeagueFixtures.forEach((f) => {
            if (currentLeagueId !== f.leagueId) {
                currentLeagueId = f.leagueId;
                currentLeague = leagues2.find(l => l.id === currentLeagueId);
            }
            if (currentLeague) {
                f.leagueName = currentLeague.title;
                fixedCount++;
            }
        });
        return fixedCount;
    }
    static async fixRoundOverviewFixtureDraws(roFixtures : RoundOverviewFixture[]) : Promise<number>{
        //Update draws where outcomes are missing
        if (!roFixtures || roFixtures.length <= 0) {
            return 0;
        }
        var drawIds : number[] = roFixtures.filter(it => it.draw && (!it.draw.outcomes || it.draw.outcomes.length <= 0)).map(it => parseInt(it.drawId));
        var drawResp = await CreateRoundUtil.getDraws(drawIds);
        if (!drawResp.success) {
            return 0;
        }
        var fixedCount = 0;
        var draws : Draw[] = drawResp.item ? drawResp.item : [];
        for (var i = 0; i < draws.length; i++) {
            var draw = draws[i];
            var roFixture = roFixtures.find(it => it.drawId === draw.id);
            if (roFixture) {
                roFixture.draw = draw;
                fixedCount++;
            }
        }
        
        return fixedCount;
    }
    static async getRoundTeamDetails(roundId: number) : Promise<GenericResponse<TeamDetail[]>> {
        var url = `rounds/${roundId}/teamDetails`;

        var resp = await ApiNgUtil.getListByUrl<TeamDetail>(url, "teamDetails");
        return CreateRoundUtil.createGenericResponse(resp.success, resp.message, resp.item?.items ? resp.item.items : null);
    }
    static async fixRoundOverviewTeamDetails(roundId: number, roFixtures : RoundOverviewFixture[]) : Promise<number>{
        var teamDetailsResp = await RoundOverviewUtil.getRoundTeamDetails(roundId);
        if (!teamDetailsResp.success || !teamDetailsResp.item) {
            return 0;
        }
        var fixedCount = 0;
        var teamDetails : TeamDetail[] = teamDetailsResp.item ? teamDetailsResp.item : [];
        var teamDetailsMap : Map<number, TeamDetail>  = new Map<number, TeamDetail>();
        teamDetails.forEach(it => teamDetailsMap.set(it.teamId, it));
        for (var i = 0; i < roFixtures.length; i++) {
            var roFixture = roFixtures[i];
            var fixture = roFixture.fixture;
            if (!fixture) {
                continue;
            }
            var team = fixture.homeTeam;
            var teamId = parseInt(team.teamId);
            var teamDetail = teamDetailsMap.get(teamId);
            roFixture.homeTeamDetail = teamDetail;
            if (teamDetail) {
                fixedCount++;
            }
            team = fixture.awayTeam;
            teamId = parseInt(team.teamId);
            teamDetail = teamDetailsMap.get(teamId);
            roFixture.awayTeamDetail = teamDetail;

            if (teamDetail) {
                fixedCount++;
            }
        }
        
        return fixedCount;
    }
    static async getRoundOverviewFixturesByRound(round : Round, leagues : LeagueListItem[], fixDraws?: boolean, includeTeamDetails?: boolean) : Promise<RoundOverviewFixture[]> {
        var roFixtures : RoundOverviewFixture[] = [];
        if (!round){
            return roFixtures;
        }
        var fixtureIds : number[] = [];
        round.fixtures.forEach(fid => { fixtureIds.push(parseInt(fid.fixtureId));});
        var fixtures = await RoundOverviewUtil.getFixturesById(fixtureIds);
        var leagueIds : number[] = [];
        for (var i = 0; i < round.fixtures.length; i++){
            var fixtureId = round.fixtures[i].fixtureId;
            var fixture : Fixture | null | undefined = (fixtures.length < i) ? null : fixtures[i];
            if (!fixture || fixture.id !== fixtureId){
                fixture = fixtures.find(f => f.id === fixtureId);
            }
            var fixtureResult : FixtureResult | undefined = (round.fixtureResults && round.fixtureResults.length < i) ? undefined : round.fixtureResults[i];
            var draw : Draw | null | undefined = null;
            if (round.draws){
                draw = round.draws.find(d => d.fixtureId === fixtureId);
            }
            var drawSign : string = "";
            var drawStatus : string;
            if (draw && draw.adminPickedOutcome && draw.adminPickedOutcome.length > 0 && draw.adminPickedOutcome !== RoundOverviewUtil.UNKNOWN_OUTCOME_SIGN) {
                if (!fixtureResult || fixtureResult.drawId !== draw.id){
                    fixtureResult = round.fixtureResults.find(fr => fr.drawId === draw?.id);
                }
                // if (fixtureResult && fixtureResult.sign === draw.adminPickedOutcome) {
                    drawSign = draw.adminPickedOutcome;
                // }
            }
            var roFixture : RoundOverviewFixture = {
                id: fixtureId,
                roundId: round.id,
                ordNo: (i + 1),
                awayScore: 0,
                draw: draw,
                drawId: draw ? draw.id : "",
                drawSign: drawSign,
                fixture: fixture,
                homeScore: 0,
                leagueId: "",
                leagueName: "",
                name: "",
                providerStatus: "",
                result: fixtureResult,
                sign: "",
                startingAt: null,
                status: "",
                title: ""
            };
            roFixtures.push(roFixture);
            if (!fixture){
                continue;
            }
            var fixtureName: string = fixture.homeTeam.teamName + " v " + fixture.awayTeam.teamName;
            roFixture.name = fixtureName;
            roFixture.title = fixtureName + " (" + CreateRoundUtil.toGameDateString(fixture.startingAt) + ")";
            roFixture.startingAt = fixture.startingAt;
            roFixture.leagueId =  fixture.leagueId;
            roFixture.leagueName = "Unknown " + fixture.leagueId;
            roFixture.homeScore = fixture.homeTeam.score;
            roFixture.awayScore = fixture.awayTeam.score;
            if (drawSign &&drawSign.length > 0) {
                roFixture.sign = drawSign;
            } else {
                roFixture.sign = RoundOverviewUtil.getScoreSign(roFixture.homeScore, roFixture.awayScore);
            }
            roFixture.status = fixture.status;
            roFixture.providerStatus = fixture.providerStatus;
            var league = leagues.find(l => l.id === fixture?.leagueId);
            if (league) {
                roFixture.leagueName = league.title;
            } else {
                var leagueId2: number = parseInt(fixture.leagueId);
                if (!isNaN(leagueId2) && !leagueIds.includes(leagueId2)) {
                    leagueIds.push(leagueId2);
                }
            }
        }

        if (leagueIds.length > 0){
            await RoundOverviewUtil.fixMissingLeagues(roFixtures, leagues, leagueIds);
        }
        if (fixDraws) {
            await RoundOverviewUtil.fixRoundOverviewFixtureDraws(roFixtures);
        }
        if (includeTeamDetails) {
            await RoundOverviewUtil.fixRoundOverviewTeamDetails(parseInt(round.id), roFixtures);
        }
        return roFixtures;
    }
    static async getRoundOverviewFixtures(roundId : string, leagues : LeagueListItem[], fixDraws?: boolean, includeTeamDetails?: boolean) : Promise<RoundOverviewFixture[]> {
        var roFixtures : RoundOverviewFixture[] = [];
        var roundResp = await RoundOverviewUtil.getRound(roundId);
        if (!roundResp.success) {
            return roFixtures;
        }
        var round = roundResp.item;
        if (!round){
            return roFixtures;
        }
        return await RoundOverviewUtil.getRoundOverviewFixturesByRound(round, leagues, fixDraws, includeTeamDetails);
    }

    static getEmptyRoundOverviewItem() : RoundOverviewItem {
        var item : RoundOverviewItem = {
            id: 0,
            betCount: undefined,
            deadline: new Date(),
            extBetCount: undefined,
            extPL: undefined,
            extTurnover: undefined,
            fixtures: null,
            roundTypeId: 0,
            roundTypeName: "",
            status: "",
            turnover: undefined,
            visible: false
        };
        return item;

    }
    static getEmptyRoundOverviewFixture() : RoundOverviewFixture {
        var roFixture : RoundOverviewFixture = {
            id: "",
            roundId: "",
            ordNo: 0,
            awayScore: 0,
            draw: null,
            drawId: "",
            drawSign: "",
            homeScore: 0,
            leagueId: "",
            leagueName: "",
            name: "",
            providerStatus: "",
            sign: "",
            startingAt: null,
            status: "",
            title: ""
        };
        return roFixture;

    }
    static createRoSettlementInput(fixtureId: number) : RoSettlementInput {
        var p = 1.0 / 3.0;
        var input: RoSettlementInput = {
            fixtureId: fixtureId,
            homeScoreText : "",
            awayScoreText : "",
            homeScore: null,
            awayScore: null,
            sign: "",
            confirmed : false,
            valid: false
        };
        return input;
    }
    static isInvalidScore(score: number, scoreString: string) : boolean {
        if (isNaN(score) || score < 0 || scoreString !== score.toString()) {
            return true;
        }
        return false;
    }
    // static getScoreSign(homeScore: number | null | undefined, awayScore : number | null | undefined): string {
    //     if (homeScore === null || homeScore === undefined || isNaN(homeScore) || homeScore < 0 || awayScore === null || awayScore === undefined || isNaN(awayScore) || awayScore < 0){
    //         return "";
    //     }
    //     if (homeScore > awayScore){
    //         return RoundOverviewUtil.ONE_OUTCOME_RESULT;
    //     } else if (homeScore < awayScore){
    //         return RoundOverviewUtil.TWO_OUTCOME_RESULT;
    //     }
    //     return RoundOverviewUtil.X_OUTCOME_RESULT;
    // }
    static getScoreSign(homeScore: number | null | undefined, awayScore : number | null | undefined): string {
        if (homeScore === null || homeScore === undefined || isNaN(homeScore) || homeScore < 0 || awayScore === null || awayScore === undefined || isNaN(awayScore) || awayScore < 0){
            return "";
        }
        if (homeScore > awayScore){
            return RoundOverviewUtil.ONE_OUTCOME_SIGN;
        } else if (homeScore < awayScore){
            return RoundOverviewUtil.TWO_OUTCOME_SIGN;
        }
        return RoundOverviewUtil.X_OUTCOME_SIGN;
    }
    static getFriendlySign(sign : string): string {
        if (!sign || sign.length === 0){
            return "";
        }
        if (sign === RoundOverviewUtil.ONE_OUTCOME_SIGN){
            return RoundOverviewUtil.ONE_OUTCOME_FRIENDLY_SIGN;
        } else if (sign === RoundOverviewUtil.TWO_OUTCOME_SIGN) {
            return RoundOverviewUtil.TWO_OUTCOME_FRIENDLY_SIGN;
        } else if (sign === RoundOverviewUtil.X_OUTCOME_SIGN) {
            return RoundOverviewUtil.X_OUTCOME_FRIENDLY_SIGN;
        }
        return "";
    }

    static getEnv() : string {
        const domain = process && process.env && process.env["REACT_APP_FIREBASE_DOMAIN"];
        if (domain?.startsWith("onextwo-dev")) {
            return this.DEV_ENVIRONMENT;
        } else  if (domain?.startsWith("onextwo-demo")) {
            return this.DEMO_ENVIRONMENT;
        } else  if (domain?.startsWith("onextwo-prod")) {
            return this.PROD_ENVIRONMENT;
        } 
        return this.UNKNOWN_ENVIRONMENT;
    }
    //Return true if env is test, if env is undefined then env is considered current environment
    static isTestEnv(env: string | undefined = undefined) : boolean {
        var envX = (env && env.length > 0) ? env : this.getEnv();
        if (envX === this.DEV_ENVIRONMENT || envX === this.DEMO_ENVIRONMENT) {
            return true;
        }
        return false;
    }
    // //Return true if current env is test
    // static isTestEnv2() : boolean {
    //     var env = this.getEnv();
    //     return this.isTestEnv(env);
    // }
    //Return true if env is prod, if env is undefined then env is considered current environment
    static isProdEnv(env: string | undefined = undefined) : boolean {
        var envX = (env && env.length > 0) ? env : this.getEnv();
        if (env === this.PROD_ENVIRONMENT) {
            return true;
        }
        return false;
    }
    //Return true if current env is prod
    static isProdEnv2() : boolean {
        var env = this.getEnv();
        return this.isProdEnv(env);
    }
    static isSettleEnabled(roundStatus: string | null | undefined, deadline: Date | null | undefined) : boolean {
        if (!roundStatus){
            return false;
        }
        if (!RoundOverviewUtil.isEnabledStatus(roundStatus)){
            return false;
        }
        if (!deadline){
            return false;
        }
        //Show settled button if more than 1 hour since deadline
        var msecsSinceDeadline =  (new Date()).getTime() - (new Date(deadline)).getTime();
        var hoursSinceDeadline = msecsSinceDeadline / 1000 / 60 / 60;
        if (hoursSinceDeadline > 1) {
            return true;
        }
        return false;
    }
    static getDrawOutcomeForMinute(draw: Draw, minute: number) : Outcome | undefined {
        if (isNaN(minute) || minute <= 0) {
            return undefined;
        }
        if (minute > 90){
            minute = 90;
        }
        var minuteValue = (minute % 10) / 10;
        if (minuteValue === 0) {
            minuteValue = 1;
        }
        var outcomes = draw.outcomes;
        if (!outcomes || outcomes.length !== 3){
            return undefined;
        }
        var sumP = 0.0;
        for (var i = 0; i < 3; i++) {
            var sign = RoundOverviewUtil.OUTCOME_SIGNS[i];
            var outcome = outcomes[i];
            if (outcome.sign != sign) {
                var outcome2 = outcomes.find(o => o.sign === sign);
                if (outcome2) {
                    outcome = outcome2;
                } else {
                    return undefined;
                }
            }
            var p = parseFloat(outcome.probability);
            if (isNaN(p) || p < 0) {
                return undefined;
            }
            sumP += p;
            if (sumP >= minuteValue) {
                return outcome;
            }
        }
        return undefined;
    }
    static async getRoundOverviews(from : Date | undefined, to: Date | undefined, limit: number) : Promise<GenericResponse<ListResponse<RoundOverviewItem>>> {
        const METHOD_NAME = "RoundOverviewUtil.getRoundOverviews";
        try {
            
            var url = `roundoverviews?from=${from?.toJSON()}&to=${to?.toJSON()}&limit=${limit}`;
            var resp = await ApiNgUtil.getListByUrl<RoundOverviewItem>(url, "roundOverviews");
            return resp;
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<ListResponse<RoundOverviewItem>>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static async getRoundTickerItems(roundId: number, limit: number, lastId: number) : Promise<GenericResponse<ListResponse<TickerItem>>> {
        const METHOD_NAME = "RoundOverviewUtil.getRoundTickerItems";
        try {
            
            var url = `ticker/rounds/${roundId}?limit=${limit}&lastId=${lastId}`;
            var resp = await ApiNgUtil.getListByUrl<TickerItem>(url, "tickerItems");
            return resp;
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<ListResponse<TickerItem>>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static addArrayRange<T>(arr: T[], other: T[]) : void {
        other.forEach(it => arr.push(it));
    }
    static formatEurAumount(amount: number | undefined) : string {
        return formatToCurrency(amount, RoundOverviewUtil.CURRENCY_EUR);
    }    
    static getRoundTitle(round: RoundOverviewItem) : string {
        var title = `${friendlyName(round.roundTypeName)} (${round.id}) ${CreateRoundUtil.toGameDateString(round.deadline)} ${round.status}`;
        return title;
    }
    static async getRoundCurrencies(roundId: number) : Promise<GenericResponse<RoundCurrency[]>>{
        const url = `roundcurrencies/rounds/${roundId}`;
        var resp = await ApiNgUtil.getListByUrl<RoundCurrency>(url, "roundCurrencies");
        if (resp.success) {
            var items : RoundCurrency[] = resp.item?.items ?? [];
            return CreateRoundUtil.createGenericResponse(true, resp.message, items);
        }
        return CreateRoundUtil.createGenericResponse<RoundCurrency[]>(false, resp.message, null);
    }
    static async saveTeamDetail(id: number, saveJson: string) : Promise<GenericResponse<TeamDetail>> {
        const METHOD_NAME = "RoundOverviewUtil.saveTeamDetail";
        var url = "teamDetails";
        var method = InvokeApiMethods.POST();
        if (id && id > 0) {
            url += `/${id}`;
            method = InvokeApiMethods.PATCH();
        }
        try {
            var resp = await ApiNgUtil.getObjectWithBody<TeamDetail>(url, method, saveJson);
            return resp;
        } catch (error) {
            console.error(METHOD_NAME + " error.", error);
            return CreateRoundUtil.createGenericResponse<TeamDetail>(false, METHOD_NAME + " error: " + error, null);
        }
    }
    static getTimestamp() : number {
        var d = new Date();
        var timestamp = d.getTime();
        return timestamp;
    }
}
export default RoundOverviewUtil;