import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, TableRow, TableCell, IconButton, makeStyles, Switch, withStyles, Tooltip } from '@material-ui/core';
import { Draw, Fixture, FixtureTeam, RoundOverviewFixture, Team, TeamDetail } from './RoundOverviewModels';
import RofResult from './RofResult';
import RoundOverviewUtil from './RoundOverviewUtil';
import { GenericResponse } from '../CreateRound/CreateRoundModels';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { Link as RouterLink } from "react-router-dom";
import DrawDialog from './DrawDialog';
import RofInfoDialog from './RofInfoDialog';
import RofTitle from './RofTitle';
import { getShirt, getShirtByColors, ShirtColors, toShirtColors } from '../common/helpers/ShirtHelper';
import TeamDetailDialog from './TeamDetailDialog';

const useStyles = makeStyles({
    fixtureResult: {
      display: 'block',
      minWidth: '2em',
      minHeight: '2em',
      border: '1',
      fontSize: '20',
      fontWeight:'bold'
    }
});
interface RoundOverviewFixtureRowState {
  updating : boolean;
  drawStatusUpdate: string;
}  

interface RoundOverviewFixtureRowProps {
  fixture: RoundOverviewFixture;
  readOnly: boolean;
  onRefreshRound?: () => void;
}

interface SpanClickViewProps {
  tag: string;
  item: JSX.Element,
  tooltip?: string;
  onClick: (tag: string) => void;
  disabled?: boolean;
  warning?: boolean;
}
const SpanClickView : React.FC<SpanClickViewProps> = (props) => {
  var span = null;
  var borderBottomx = props.warning ? "2px solid red" : "";
  if (props.disabled) {
    span = (<span key={`SpanClickView_${props.tag}`}>{props.item}</span>);
  } else {
    span = (<span key={`SpanClickView_${props.tag}`} style={{cursor: "pointer", marginRight:"2px", borderBottom: `${borderBottomx}` }} onClick={e => props.onClick(props.tag)}>{props.item}</span>);
  }
  if (props.tooltip && props.tooltip.length > 0) {
    return (
      <Tooltip key={`SpanClickView_Tooltip_${props.tag}`} title={props.tooltip} >
        {span}
      </Tooltip>
    );
  }
  return (
    <>
        {span}
    </>
  );
}

const RoundOverviewFixtureRow: React.FC<RoundOverviewFixtureRowProps> = (props) => {
  const classes = useStyles();
  const [updateDrawResp, setUpdateDrawResp] = useState<GenericResponse<Draw>>({
    success:true,
    message: "",
    item: null
  });
  const [rowState, setRowState] = useState<RoundOverviewFixtureRowState>({
    updating:false,
    drawStatusUpdate: ""
  });
  const [teamDetailOpen, setTeamDetailOpen] = useState<string>("");

  const beginChangeDrawStatus = (status: string) => {
    setRowState({
      updating: true,
      drawStatusUpdate: status
    });
  };

  const endUpdating = () => {
    setRowState({
      updating: false,
      drawStatusUpdate: ""
    });
  };  
  const onChangeDrawStatus = (status: string) => {
    const methodName = "onChangeDrawStatus." + status;
    beginChangeDrawStatus(status);
    var updateJson = JSON.stringify({status: status});
    CreateRoundUtil.updateDraw(props.fixture.drawId, updateJson).then(resp => {
      setUpdateDrawResp(resp);
      if (resp.success){
        if (resp.item) {
          props.fixture.draw = resp.item;
        }
      } else {
        alert(resp.message);
      }
      endUpdating();
    }).catch(error => {
      setUpdateDrawResp(CreateRoundUtil.createGenericResponse<Draw>(false, methodName + " error: " + error, null))
      endUpdating();
    });
  }
  const onDrawClose = (draw: Draw | undefined) => {
    if (!draw) {
      return;
    }
    props.fixture.draw = draw;
    if (props.onRefreshRound) {
      props.onRefreshRound();
    }
  }
  const onFixtureChange = (fixture?: Fixture) => {
    if (!fixture) {
      return;
    }
    if (props.onRefreshRound) {
      props.onRefreshRound();
    }
  }

  const onTeamClick = (team: string) => {
    //alert(`onTeamClick(home:${home})`);
    setTeamDetailOpen(team);
  }
  const onTeamDetailClose = (teamDetail: TeamDetail | undefined) : void => {
    if (teamDetailOpen.length > 0) {
      setTeamDetailOpen("");
    }
    if (!teamDetail) {
      return;
    }
    var homeTeamId = 0;
    if (props.fixture.homeTeamDetail) {
      homeTeamId = props.fixture.homeTeamDetail.teamId;
    } else if (props.fixture.fixture?.homeTeam) {
      homeTeamId = parseInt(props.fixture.fixture.homeTeam.teamId);
    }
    if (teamDetail.teamId === homeTeamId) {
        props.fixture.homeTeamDetail = teamDetail;
        setTeamDetailOpen("HomeX");
        return;
    }
    var awayTeamId = 0;
    if (props.fixture.awayTeamDetail) {
      awayTeamId = props.fixture.awayTeamDetail.teamId;
    } else if (props.fixture.fixture?.awayTeam) {
      awayTeamId = parseInt(props.fixture.fixture.awayTeam.teamId);
    }
    if (teamDetail.teamId === awayTeamId) {
      props.fixture.awayTeamDetail = teamDetail;
      setTeamDetailOpen("AwayX");
    }
  
  }
  
  var row = props.fixture;
  var willBeDrawnSwitch = null;
  var drawStatus = row.draw?.status;
  if (rowState.updating){
    if (rowState.drawStatusUpdate.length > 0){
      drawStatus = rowState.drawStatusUpdate;
    }
  }
  var enabledStatus =  !props.readOnly && RoundOverviewUtil.isEnabledDrawStatus(drawStatus);

  var drawDlg = null;
  if (drawStatus && enabledStatus) {
    var willBeDrawn = (drawStatus === RoundOverviewUtil.PUBLISHED_STATUS);
    var newStatus = willBeDrawn ? RoundOverviewUtil.PENDING_STATUS : RoundOverviewUtil.PUBLISHED_STATUS;
    willBeDrawnSwitch = (<Switch
      checked={willBeDrawn}
      onChange={(e: any) => onChangeDrawStatus(newStatus)}
      inputProps={{ 'aria-label': 'controlled' }}
      disabled={rowState.updating}
    />
    );

    if (willBeDrawn){
        drawDlg = (<DrawDialog fixture={row} open={false} onClose={onDrawClose} />);
    }

  }
  var drawInfo = null;
  var drawInfo2 = null;
  var draw = row.draw;
  if (draw) {
    drawInfo = `${draw.adminPickedOutcome} (${draw.status})`;
  }

  var homeDetail = row.homeTeamDetail;
  var homeColors : ShirtColors = homeDetail ? toShirtColors(homeDetail.primaryColor, homeDetail.kitColors) : toShirtColors(null, null);
  var homeShirt = getShirtByColors(homeColors, 0.5);
  
  var awayDetail = row.awayTeamDetail;
  var awayColors : ShirtColors = awayDetail ? toShirtColors(awayDetail.primaryColor, awayDetail.kitColors) : toShirtColors(null, null);
  var awayShirt = getShirtByColors(awayColors, 0.5);

  var homeTeam = row.fixture?.homeTeam;
  var awayTeam = row.fixture?.awayTeam;
  var homeTeamName = homeTeam?.teamName;
  var awayTeamName = awayTeam?.teamName;
  var homeTeamTitle = homeColors.type === "KitColors4" ? homeTeamName : `${homeTeamName} (*${homeColors.type}*)`;
  var awayTeamTitle = awayColors.type === "KitColors4" ? awayTeamName : `${awayTeamName} (*${awayColors.type}*)`;
  var teamDetailDialog = null;
  if (!rowState.updating && teamDetailOpen && teamDetailOpen.length > 0 && !teamDetailOpen.endsWith("X")) {
    var teamDetail : TeamDetail | undefined = undefined;
    var team : FixtureTeam | undefined = undefined;
    if (teamDetailOpen === "Home") {
      teamDetail = homeDetail;
      team = homeTeam;
    } else if (teamDetailOpen === "Away") {
      teamDetail = awayDetail;
      team = awayTeam;
    }
    var teamId = 0;
    var teamName = "";
    if (teamDetail) {
      teamId = teamDetail.teamId;
      teamName = teamDetail.teamName;
    } else if (team) {
      teamId = parseInt(team.teamId);
      teamName = team.teamName;
    }
    if (teamId > 0) {
      teamDetailDialog = (
        <TeamDetailDialog open={true} teamId={teamId} teamName={teamName} timestamp={RoundOverviewUtil.getTimestamp()} teamDetail={teamDetail} onClose={onTeamDetailClose} />
      );
    }
  }
  return (
    <TableRow key={row.id} >
      <TableCell component="th" scope="row">
        {row.ordNo}
      </TableCell>
      <TableCell>
        <RofTitle fixture={row} editEnabled={true} onChange={onFixtureChange} />
      </TableCell>
      <TableCell>
        <SpanClickView tag='Home' item={homeShirt} tooltip={homeTeamTitle} onClick={k => onTeamClick(k)} disabled={rowState.updating} warning={homeColors.type !== "KitColors4"} />
        <SpanClickView tag='Away' item={awayShirt} tooltip={awayTeamTitle} onClick={k => onTeamClick(k)} disabled={rowState.updating} warning={awayColors.type !== "KitColors4"} />
        {teamDetailDialog}
      </TableCell>
      <TableCell>
        <RofResult fixture={row} />
      </TableCell>
      <TableCell>
        {drawInfo}
        {drawInfo2}
      </TableCell>
      <TableCell>
        {willBeDrawnSwitch}
      </TableCell>
      <TableCell>
        <Box display="flex" padding={0} margin="0" >
          <RofInfoDialog fixture={row} openButtonLabel="Info" />&nbsp;{drawDlg}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default RoundOverviewFixtureRow;



