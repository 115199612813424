import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@material-ui/core';
import { Round, RoundOverviewItem } from './RoundOverviewModels';
import RoundOverviewRow from './RoundOverviewRow';
import DataTable, { CreateDataRowContext, DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: '100%',
//     },
//     paper: {
//       width: '100%',
//       marginBottom: theme.spacing(2),
//     },
//     table: {
//       minWidth: 750,
//     },
//     visuallyHidden: {
//       border: 0,
//       clip: 'rect(0 0 0 0)',
//       height: 1,
//       margin: -1,
//       overflow: 'hidden',
//       padding: 0,
//       position: 'absolute',
//       top: 20,
//       width: 1,
//     },
//     tableRow: {
//       hover:{
//         cursor: "pointer"
//       }
//     }
//   }),
// );


interface RoundOverviewTableProps {
    rounds: RoundOverviewItem[];
    onSelect? : (item : RoundOverviewItem) => void;
    onVisibleChange?: (item: RoundOverviewItem) => void;
    onPause?: (item: RoundOverviewItem) => void;
    onActivate?: (item: RoundOverviewItem) => void;
}

const RoundOverviewTable: React.FC<RoundOverviewTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedRoundId, setSelectedRoundId] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<keyof RoundOverviewItem>('id');
    const [order, setOrder] = useState<Order>('desc');
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [resultFilter, setResultFilter] = useState<string>("");
    const [columns, setColumns] = useState<DataTableColumn<RoundOverviewItem>[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<RoundOverviewItem>[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Round Id" },
        { id: 'roundTypeName', numeric: false, disablePadding: false, label: 'Round Type' },
        { id: 'deadline', numeric: false, disablePadding: false, label: 'Deadline' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'turnover', numeric: true, disablePadding: false, label: 'Turnover' },
        { id: 'extTurnover', numeric: true, disablePadding: false, label: 'Ext Turnover' },
        { id: 'extBetCount', numeric: true, disablePadding: false, label: '#Ext Bets' },
        { id: 'extPL', numeric: true, disablePadding: false, label: 'Ext PL' },
        { id: 'visible', numeric: false, disablePadding: false, label: 'Visible' },
        { id: undefined, numeric: false, disablePadding: false, label: 'Action' }
      ];
      setColumns(cols);
    }
    const onSelect = (item : RoundOverviewItem) => {
        setSelectedRoundId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    const onVisibleChange = (item : RoundOverviewItem) => {
      if (props.onVisibleChange){
        props.onVisibleChange(item);
        return;
    }
  };
  const onPause = (item : RoundOverviewItem) => {
    if (props.onPause) {
      props.onPause(item);
      return;
    }
  };
  const onActivate = (item : RoundOverviewItem) => {
    if (props.onActivate) {
      props.onActivate(item);
      return;
    }
  };
  const createRow = (item : RoundOverviewItem, context: CreateDataRowContext<RoundOverviewItem>) : JSX.Element =>  {
    return (<RoundOverviewRow round={item} context={context} />);
  };

  return (
    <DataTable columns={columns} items={props.rounds} getId={it => it.id} orderBy='id' order='desc' resultFilterEnabled={false} size='xsmall' createRow={createRow} onSelect={onSelect}  />
    );
};
  
export default RoundOverviewTable;
