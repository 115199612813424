import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Link, TableCell, TableRow } from '@material-ui/core';
import DataTable, { CreateDataRowContext, DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { formatNumber } from '../common/helpers/CurrencyHelpers';
import { FixtureTeam, Fixture } from './TeamOverviewModels';
import { TeamDetail } from '../RoundOverview/RoundOverviewModels';
import FixtureLabel from '../Components/FixtureLabel';
import FixtureTeamShirtView from './FixtureTeamShirtView';



interface FixtureRowProps {
  item: Fixture;
  context: CreateDataRowContext<Fixture>;
}
const FixtureRow: React.FC<FixtureRowProps> = (props) => {
  var item = props.item;
  return (
    <TableRow
      hover
      selected={props.context.isSelected(item)}
      onClick={e => props.context.onClick(item)}
      className={props.context.tableRowClass}
    >
      <TableCell><Link component={RouterLink} to={`fixtures/${item.id}`}>{item.id}</Link></TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{CreateRoundUtil.toGameDateString(item.startingAt)}</TableCell>
      <TableCell>{item.leagueTitle}</TableCell>
      <TableCell>{item.status}</TableCell>
      <TableCell>{item.score}</TableCell>
      <TableCell>{item.roundCount}</TableCell>
      <TableCell>
        <FixtureTeamShirtView item={item.homeTeam} />
        <FixtureTeamShirtView item={item.awayTeam} />
      </TableCell>
    </TableRow>
  );

}

interface FixtureTableProps {
  items: Fixture[];
  onSelect? : (item : Fixture) => void;
  onDeselect? : () => void;
}

const FixtureTable: React.FC<FixtureTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<Fixture>[]>([]);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<Fixture>[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Fixture Id"},
        { id: 'name', numeric: false, disablePadding: false, label: 'Fixture'},
        { id: 'startingAt', numeric: false, disablePadding: false, label: 'Starting at'},
        { id: 'leagueTitle', numeric: false, disablePadding: false, label: 'League'},
        { id: 'status', numeric: false, disablePadding: false, label: 'Status'},
        { id: 'score', numeric: false, disablePadding: false, label: 'Score'},
        { id: 'roundCount', numeric: false, disablePadding: false, label: '#Rounds'},
        { id: 'shirtsOrder', numeric: false, disablePadding: false, label: 'Shirts' },
      ];
      setColumns(cols);
    }
    const onSelect = (item : Fixture) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    const onDeselect = () => {
      setSelectedId(0);
      if (props.onDeselect){
        props.onDeselect();
        return;
      }
    };
 
    const createRow = (item : Fixture, context: CreateDataRowContext<Fixture>) : JSX.Element => {
      return (
        <FixtureRow item={item}  context={context} />
      );
    };
  return (
    <DataTable columns={columns} items={props.items} createRow={createRow}  getId={it => it.id} orderBy='id' order='asc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect} onDeselect={onDeselect} />
    );
};
  
export default FixtureTable;
