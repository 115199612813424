import React from "react";

export type ShirtType = "Default" | "PrimaryColor" | "KitColors4" | "KitColors11";

export interface ShirtColors {
    type: ShirtType;
    base: string;
    stripes: string;
    arms: string;
    collar: string;
}
export const StripedShirt = (height = 213.7, width = 198.8, inside = "rgb(200,200,200)", arms = "rgb(200,200,200)", collar = "rgb(200,200,200)", base = "rgb(200,200,200)", stripes = "rgb(200,200,200)") => (
    <span>
        <svg width={width} height={height} viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M203.006 109.193h93.643V21.8979h-93.643v87.2951z" fill={inside}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M202.866 32.198h93.459v-14.2h-93.459v14.2z" fill={collar} />
            <path fillRule="evenodd" clipRule="evenodd" d="M87.5472 75.2603S46.7846 148.061 29 203.031c4.4449 13.376 45.2074 40.861 85.232 37.886 13.339-36.401 24.459-52.001 24.459-52.001s2.963-95.0856-51.1438-113.6557zm323.9258 0s40.762 72.8007 58.547 127.7707c-4.445 13.376-45.208 40.861-85.232 37.886-13.34-36.401-24.459-52.001-24.459-52.001s-2.964-95.0856 51.144-113.6557z" fill={arms} />
            <path fillRule="evenodd" clipRule="evenodd" d="M249.513 57.7054c-50.081 0-47.798-39.4691-48.742-39.14-7.516 2.6187-24.133 14.9368-52.439 25.495-31.873 11.885-52.6256 19.315-60.7822 31.2 11.1149 8.915 46.6942 28.9696 46.6942 120.3446v271.14S183.902 481.6 249.129 481.6c68.929 0 115.649-14.855 115.649-14.855v-271.14c0-91.375 35.579-111.4296 46.699-120.3446-8.157-11.885-28.91-19.315-60.783-31.2-28.359-10.5767-44.981-22.9194-52.478-25.5087-.927-.3202 4.178 39.1537-48.703 39.1537z" fill={base} />
            <path fillRule="evenodd" clipRule="evenodd" d="M270.787 54.9414c30.122-8.9322 26.652-36.6915 27.43-36.4243.165.0569.334.1186.507.1848V478.998c-8.646.901-17.989 1.633-27.937 2.081V54.9414zm69.519-15.0678a239.5368 239.5368 0 0010.389 4.1541c5.52 2.0584 10.707 3.9832 15.552 5.8206V165.305c-.943 9.108-1.469 19.161-1.469 30.268v271.14s-8.828 2.807-24.472 5.981V39.8736zm-111.1 441.2404c-9.848-.445-19.207-1.19-27.937-2.114V21.5963c1.009 7.4264 4.89 26.2876 27.937 33.3507v426.167zm-69.519-8.402c-16.023-3.181-25.442-5.999-25.442-5.999v-271.14c0-6.371-.173-12.394-.499-18.092V49.4826c4.567-1.7269 9.431-3.5326 14.587-5.4549 4.018-1.4988 7.8-3.0329 11.354-4.5698V472.712z" fill={stripes} />
            <path fillRule="evenodd" clipRule="evenodd" d="M249.577 63.7514c51.478 0 58.278-35.895 58.278-40.38-5.772-3.24-9.728-5.345-12.008-5.345 0 0 5.882 32.495-46.335 32.495-48.52 0-46.335-32.495-46.335-32.495-2.28 0-6.236 2.105-12.003 5.345 0 0 7.368 40.38 58.403 40.38z" fill={collar} />
            <path fillRule="evenodd" clipRule="evenodd" d="M413.584 238.323c-9.288 2.238-19.065 3.316-28.798 2.592-8.198-22.369-15.557-36.883-20.013-44.748v270.577s-21.102 6.71-55.866 11.111l-.544-.06c1.881-54.795 5.722-166.47 8.276-240.705 2.056-59.755 31.085-95.29 60.099-117.44-2.675 6.781-5.071 14.606-6.999 23.667 7.354 11.852 24.436 42.069 43.831 94.964.005.014.01.028.014.042zM190.716 477.795c-34.248-4.401-56.476-11.051-56.476-11.051V196.172c-4.456 7.867-11.814 22.379-20.01 44.743-9.633.716-19.3084-.332-28.5103-2.524a1.056 1.056 0 01.0199-.096c19.3774-52.848 36.3054-83.066 43.6094-94.944-1.928-9.075-4.327-16.912-7.006-23.701 29.014 22.15 58.044 57.685 60.099 117.44 2.554 74.235 6.396 185.91 8.276 240.705h-.002z" fill="#000" fillOpacity=".1" />
        </svg>
    </span>
);

export function toShirtColors(primaryColor: string | undefined | null, kitColors: string[] | undefined | null) : ShirtColors {
    var type : ShirtType = "PrimaryColor";
    var color = primaryColor;
    if (!color) {
        type = "Default";
        color = "#E6E6E6";
    }

    let arms = color;
    let collar = color;
    let stripes = color;
    let base = color;

    if (kitColors) {
        if (kitColors.length === 4) {
            type = "KitColors4";
            base = kitColors[0];
            stripes = kitColors[1];
            arms = kitColors[2];
            collar = kitColors[3];
        } else if (kitColors.length === 11) {
            type = "KitColors11";
            base = kitColors[1];
            arms = kitColors[0];
            collar = kitColors[10];
            stripes = kitColors[2];
        }
    }

    return {
        type: type,
        base: base,
        stripes: stripes,
        arms: arms,
        collar: collar
    };
}

export function toKitColors(colors: ShirtColors) : string[] {
    var kitColors : string[] = [colors.base, colors.stripes, colors.arms, colors.collar];
    return kitColors;
}

export function getShirtByColors(colors: ShirtColors, sizeRatio: number = 1) : JSX.Element {
    var height = 58;
    var width = 60;
    if (sizeRatio !== 1) {
        height *= sizeRatio;
        width *= sizeRatio;
        height = Math.round(height);
        width = Math.round(width);
    }
    return StripedShirt(height, width, darkenColor(colors.base, -20), colors.arms, colors.collar, colors.base, colors.stripes)

}

export function getShirt(primaryColor: string | undefined | null, kitColors: string[] | undefined | null, sizeRatio: number = 1) : JSX.Element {
    var colors = toShirtColors(primaryColor, kitColors);
    return getShirtByColors(colors, sizeRatio);
}

function darkenColor(color: string, percent: number) {

    if (color === null || color === undefined) {
        return color;
    }

    // Strip the leading # if it's there
    color = color.replace(/^\s*#|\s*$/g, "");

    // Convert 3 char codes -> 6, e.g. `E0F` -> `EE00FF`
    if (color.length === 3) {
        color = color.replace(/(.)/g, "$1$1");
    }

    // Split HEX Color
    const hexR = color.substring(0, 2);
    const hexG = color.substring(2, 4);
    const hexB = color.substring(4, 6);

    // HEX to RGB
    let r = parseInt(hexR, 16);
    let g = parseInt(hexG, 16);
    let b = parseInt(hexB, 16);

    if (isNaN(r)) r = 0;
    if (isNaN(g)) g = 0;
    if (isNaN(b)) b = 0;

    // Manipulate
    const newR = Math.min(255, Math.floor(r + (r * percent) / 100));
    const newG = Math.min(255, Math.floor(g + (g * percent) / 100));
    const newB = Math.min(255, Math.floor(b + (b * percent) / 100));

    // RGB to HEX
    const newHexRColor = `${newR.toString(16)}`.padStart(2, "0");
    const newHexGColor = `${newG.toString(16)}`.padStart(2, "0");
    const newHexBColor = `${newB.toString(16)}`.padStart(2, "0");

    return "#" + newHexRColor + newHexGColor + newHexBColor;
}
