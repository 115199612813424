import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Link, TableCell, TableRow } from '@material-ui/core';
import DataTable, { CreateDataRowContext, DataTableColumn, Order, useDataTableStyles } from '../Components/DataTable';
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import { formatNumber } from '../common/helpers/CurrencyHelpers';
import { FixtureTeam, TeamOverviewItem } from './TeamOverviewModels';
import ShirtView from '../Components/ShirtView';
import { TeamDetail } from '../RoundOverview/RoundOverviewModels';
import FixtureLabel from '../Components/FixtureLabel';
import FixtureTeamShirtView from './FixtureTeamShirtView';

interface TeamShirtViewProps {
  item: TeamOverviewItem;
  onTeamDetailChange: (detail: TeamDetail) => void;
}
const TeamShirtView: React.FC<TeamShirtViewProps> = (props) => {
  const [changeNo, setChangeNo] = useState<number>(0);
  var item = props.item;
  const onTeamDetailChange = (detail? : TeamDetail) => {
    if (detail) {
      item.detail = detail;
      setChangeNo(changeNo + 1);
      if (props.onTeamDetailChange) {
        props.onTeamDetailChange(detail);
      }
    }
  };
  return (
    <ShirtView teamId={item.id} teamName={item.name} teamDetail={item.detail} onTeamDetailChange={onTeamDetailChange} />
  );
}


interface TeamOverviewRowProps {
  item: TeamOverviewItem;
  context: CreateDataRowContext<TeamOverviewItem>;
  onTeamDetailChange: (detail: TeamDetail) => void;
  //colums: DataTableColumn<TeamOverviewItem>[]
}
const TeamOverviewRow: React.FC<TeamOverviewRowProps> = (props) => {
  var item = props.item;
  const onTeamDetailChange = (detail? : TeamDetail) => {
    if (detail) {
      item.detail = detail;
      if (props.onTeamDetailChange) {
        props.onTeamDetailChange(detail);
      }
    }
  };
  return (
    <TableRow
      key={item.id} 
      hover={true}
      selected={props.context.isSelected(item)}
      onClick={e => props.context.onClick(item)}
      className={props.context.tableRowClass}
      >
      <TableCell><Link component={RouterLink} to={`teams/${item.id}`}>{item.id}</Link></TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{CreateRoundUtil.toGameDateString(item.createdAt)}</TableCell>
      <TableCell><TeamShirtView item={item} onTeamDetailChange={onTeamDetailChange}/></TableCell>
      <TableCell>{item.fixtureCount}</TableCell>
      <TableCell>{item.roundCount}</TableCell>
      <TableCell>{item.nextFixture ? (<FixtureLabel fixture={item.nextFixture} />) : null}</TableCell>
      <TableCell>{item.nextFixture ? (
        <>
        <FixtureTeamShirtView item={item.nextFixture.homeTeam} onTeamDetailChange={onTeamDetailChange} />
        <FixtureTeamShirtView item={item.nextFixture.awayTeam} onTeamDetailChange={onTeamDetailChange} />
        </>) : null}
      </TableCell>
    </TableRow>
  );

}

interface TeamOverviewTableProps {
  items: TeamOverviewItem[];
  onSelect? : (item : TeamOverviewItem) => void;
  onDeselect? : () => void;
}

const TeamOverviewTable: React.FC<TeamOverviewTableProps> = (props) => {
    const classes = useDataTableStyles();
    const [selectedId, setSelectedId] = useState<number>(0);
    const [columns, setColumns] = useState<DataTableColumn<TeamOverviewItem>[]>([]);
    const [changeNo, setChangeNo] = useState<number>(0);

    useEffect(() => {
      refreshColumns();
    }, []);
    
    const refreshColumns = () => {
      var cols: DataTableColumn<TeamOverviewItem>[] = [
        { id: 'id', numeric: false, disablePadding: true, label: "Team Id", renderValue: (item: TeamOverviewItem) => { return (<Link component={RouterLink} to={`teams/${item.id}`}>{item.id}</Link>); }},
        { id: 'name', numeric: false, disablePadding: false, label: 'Team' },
        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created at', renderValue: (item: TeamOverviewItem) => CreateRoundUtil.toGameDateString(item.createdAt) },
        { id: 'shirtsOrder', numeric: false, disablePadding: false, label: 'Shirt', renderValue: (item: TeamOverviewItem) => { return (<TeamShirtView item={item} onTeamDetailChange={onTeamDetailChange} />);} },
        { id: 'fixtureCount', numeric: false, disablePadding: false, label: '#Fixtures'},
        { id: 'roundCount', numeric: false, disablePadding: false, label: '#Rounds'},
        { id: 'nextFixtureOrder', numeric: false, disablePadding: false, label: 'Next fixture'},
        { id: 'fixtureTeamShirtsOrder', numeric: false, disablePadding: false, label: 'Shirts' },
      ];
      setColumns(cols);
    }
    const onClick = (item : TeamOverviewItem) => {
      if (selectedId === item.id) {
        onDeselect();
      } else {
        onSelect(item);
      }
    };
    const onSelect = (item : TeamOverviewItem) => {
        setSelectedId(item.id);
        if (props.onSelect){
          props.onSelect(item);
          return;
      }
    };
    const onDeselect = () => {
      setSelectedId(0);
      if (props.onDeselect){
        props.onDeselect();
        return;
      }
    };
    const onTeamDetailChange = (detail? : TeamDetail) => {
      if (!detail) {
        return;
      }
      var changeCount = 0;
      for (var team of props.items) {
        if (team.id === detail.teamId) {
          team.detail = detail;
        }
        var nextFixture = team.nextFixture;
        if (!nextFixture) {
          continue;
        }
        if (nextFixture.homeTeam && nextFixture.homeTeam.id === detail.teamId) {
          nextFixture.homeTeam.detail = detail;
          changeCount++;
        }
        if (nextFixture.awayTeam && nextFixture.awayTeam.id === detail.teamId) {
          nextFixture.awayTeam.detail = detail;
          changeCount++;
        }
      }
      if (changeCount > 0) {
        setChangeNo(changeNo + 1);
      }

    };
    const renderShirt = (item : TeamOverviewItem) : JSX.Element => {
      return (
        <TeamShirtView item={item} onTeamDetailChange={onTeamDetailChange}/>
      );
    };
 
    const createRow = (item : TeamOverviewItem, context: CreateDataRowContext<TeamOverviewItem>) : JSX.Element => {
      return (
        <TeamOverviewRow item={item} context={context} onTeamDetailChange={onTeamDetailChange}/*selected={item.id === selectedId} onClick={onClick}*//>
      );
    };
  return (
    <DataTable columns={columns} items={props.items} createRow={createRow} getId={it => it.id} orderBy='id' order='asc' resultFilterEnabled={false} size='xsmall' onSelect={onSelect} onDeselect={onDeselect} />
    );
};
  
export default TeamOverviewTable;
