import React, { useState } from 'react';
import { Button, Link, Switch, TableCell, TableRow, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Round, RoundOverviewItem } from "./RoundOverviewModels";
import CreateRoundUtil from '../CreateRound/CreateRoundUtil';
import fetchApi from '../../fetchApi';
import RoundOverviewUtil from './RoundOverviewUtil';
import { formatToCurrency } from "../common/helpers/CurrencyHelpers";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { CreateDataRowContext } from '../Components/DataTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      "&:hover":{
        cursor: "pointer"
      }
    }
  }),
);

interface UpdateRoundResponse {
  success: boolean,
  message: string,
  round: Round | null
}

interface RoundOverviewRowState {
  updating : boolean,
  visibleUpdate : number,
  statusUpdate: string
}
interface RoundOverviewRowProps {
  round: RoundOverviewItem;
  // selected: boolean,
  // onSelect?: (item: RoundOverviewItem) => void;
  onVisibleChange?: (item: RoundOverviewItem) => void;
  onPause?: (item: RoundOverviewItem) => void;
  onActivate?: (item: RoundOverviewItem) => void;
  context: CreateDataRowContext<RoundOverviewItem>;
}
const RoundOverviewRow: React.FC<RoundOverviewRowProps> = (props) => {
  const classes = useStyles();
  const [updateRoundResp, setUpdateRoundResp] = useState<UpdateRoundResponse>({
    success:true,
    message: "",
    round: null
  });
  const [roundState, setRoundState] = useState<RoundOverviewRowState>({
    updating:false,
    visibleUpdate: 0,
    statusUpdate: ""
  });
  const history = useHistory();

  // const onSelect = () => {
  //   if (props.onSelect) {
  //     props.onSelect(props.round);
  //     return;
  //   }
  //   alert("RoundOverviewRow.onSelect property not set.");
  // };
  const createUpdateRoundResponse = (success: boolean, message: string, round : Round | null) : UpdateRoundResponse => {
    return {
      success: success,
      message: message,
      round: round
    };
  }
  const beginChangeStatus = (status: string) => {
    setRoundState({
      updating: true,
      statusUpdate: status,
      visibleUpdate:  0
    });
  };

  const endUpdating = () => {
    setRoundState({
      updating: false,
      statusUpdate: "",
      visibleUpdate: 0
    });
  };  

  // const updateRound = (saveRoundJson: string, caller: string, action : (success: boolean, message : string) => void) => {
  const updateRound = async (saveRoundJson: string, caller: string) : Promise<UpdateRoundResponse> => {
    const methodName = "UpdateRound." + caller;
    try {

        var respX = await fetchApi("rounds/" + props.round.id, {
            method: 'PATCH',
            body: saveRoundJson
        });
        var round : Round = respX.json;
        if (!round) {
            //onSaveError(methodName + " failure: no response.");
            return createUpdateRoundResponse(false, methodName + " failure: no response.", null);
        }
        var rId: string = round.id;
        if (!rId) {
            //onSaveError(methodName + " failure: id property is missing in response.");
            return createUpdateRoundResponse(false, methodName + " failure: id property is missing in response.", round);
        }
        rId = rId.trim();
        if (rId.length <= 0) {
            //onSaveError(methodName + " failure: id property is an empty string.");
            return createUpdateRoundResponse(false, methodName + " failure: id property is an empty string.", round);
        }
        //addSaveInfo("Update Round (datebase) done");
        return createUpdateRoundResponse(true, "", round);
    } catch (error){
      console.error(methodName + " error!", error);
      return createUpdateRoundResponse(false, methodName + " error: " + error, null);

    }
  }
  const changeStatus = async (status: string, caller: string) : Promise<UpdateRoundResponse> => {
    const methodName = "ChangeStatus." + caller;
    try {
      let saveRoundJson = JSON.stringify({
        status: status
      });
      var resp = await updateRound(saveRoundJson, caller);
      if (resp.success){
        if (resp.round){
          props.round.status = resp.round.status;
        }
      }
      return resp;
    } catch (error){
      console.error(methodName + " error!", error);
      return createUpdateRoundResponse(false, methodName + " error: " + error, null);
    }
  }
  

  const onVisibleChange = () => {
    setRoundState({
      updating: true,
      statusUpdate: "",
      visibleUpdate:  props.round.visible ? -1 : 1
    });
    let saveRoundJson = JSON.stringify({
      visibleToPlayers: !props.round.visible
    });
    updateRound(saveRoundJson, "onVisibleChange").then(resp => {
      if (resp.success){
        if (resp.round){
          props.round.visible = resp.round.visibleToPlayers;
          if (props.onVisibleChange) {
            props.onVisibleChange(props.round);
          }
        }
      } else {
        alert(resp.message);
      }
      //setUpdateRoundResp(resp);
      endUpdating();
    });
    // if (props.onVisibleChange) {
    //   props.onVisibleChange(props.round);
    //   return;
    // }
    // alert("RoundOverviewRow.onVisibleChange property not set.");
  };
  const onPause = () => {
    var status = "PAUSED";
    var method = "onPause";
    beginChangeStatus(status)
    changeStatus(status, method).then(resp => {
      if (resp.success){
          if (props.onPause) {
            props.onPause(props.round);
          }
      } else {
        alert(resp.message);
      }
      endUpdating();
    });
    //alert("RoundOverviewRow.onPause property not set.");
  };
  const onActivate = () => {
    var status = "ACTIVE";
    var method = "onActivate";
    beginChangeStatus(status)
    changeStatus(status, method).then(resp => {
      if (resp.success){
        if (props.onActivate) {
          props.onActivate(props.round);
        }
      } else {
        alert(resp.message);
      }
      endUpdating();
    });
    // if (props.onActivate) {
    //   props.onActivate(props.round);
    //   return;
    // }
    // alert("RoundOverviewRow.onActivate property not set.");
  };
  
  const onSettle = () => {
    history.push('settleRound/' + props.round.id);
  };

  var row = props.round;
  var visibleSwitch = null;
  var btn = null;
  var settleBtn = null;
  var actionBtns : any[] = [];
  var roundVisible = row.visible;
  var roundStatus = row.status;
  var enabledStatus =  RoundOverviewUtil.isEnabledStatus(row.status);
  if (roundState.updating){
    switch (roundState.visibleUpdate) {
      case 1:
        roundVisible = true;
        break;
      case -1:
        roundVisible = false;
        break;
    }
    if (roundState.statusUpdate.length > 0){
      roundStatus = roundState.statusUpdate;
    }
  }
  switch (roundStatus){
    case "ACTIVE":
      btn = (<Button variant="outlined" color="secondary" size="small" disabled={roundState.updating} onClick={(e: any) => onPause()}>Pause</Button>);
      break;
    case "PAUSED":
    case "PENDING":
        btn = (<Button variant="outlined" color="secondary" size="small" disabled={roundState.updating} onClick={(e: any) => onActivate()}>Activate</Button>);
        break;
  }
  if (btn){
    actionBtns.push(btn);
  }
  // if (enabledStatus){
    //Show settled button if more than 1 hour since deadline
    // var msecsSinceDeadline =  (new Date()).getTime() - (new Date(row.deadline)).getTime();
    // var hoursSinceDeadline = msecsSinceDeadline / 1000 / 60 / 60;
    // if (hoursSinceDeadline > 1) {
    //   settleBtn = (<Button variant="outlined" color="secondary" size="small" disabled={roundState.updating} onClick={(e: any) => onSettle()}>Settle</Button>);
    //   actionBtns.push(settleBtn);
    // }
//  }
//}
  if (RoundOverviewUtil.isSettleEnabled(row.status, row.deadline)) {
      settleBtn = (<Button variant="outlined" color="secondary" size="small" disabled={roundState.updating} onClick={(e: any) => onSettle()}>Settle</Button>);
      actionBtns.push(settleBtn);     
  }

  var currency = RoundOverviewUtil.CURRENCY_EUR;
  return (
    <TableRow 
        key={row.id} 
        hover
        onClick={(e: any) => props.context.onClick(row)}
        selected={props.context.isSelected(row)}
        className={props.context.tableRowClass}
    >
      <TableCell component="th" scope="row">
        <Link component={RouterLink} to={"rounds/" + row.id}>{row.id}</Link>
      </TableCell>
      <TableCell>
        {row.roundTypeName}
      </TableCell>
      <TableCell>
        {CreateRoundUtil.toGameDateString(row.deadline)}
      </TableCell>
      <TableCell>
        {row.status}
      </TableCell>
      <TableCell align='right'>
        {formatToCurrency(row.turnover, currency)}
       </TableCell>
       <TableCell align='right'>
        {formatToCurrency(row.extTurnover, currency)}
       </TableCell>
       <TableCell align='right'>
        {row.extBetCount}
      </TableCell>
       <TableCell align='right'>
        {formatToCurrency(row.extPL, currency)}
       </TableCell>
       <TableCell >
        <Switch
            checked={roundVisible}
            onChange={(e: any) => onVisibleChange()}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={roundState.updating}
            size="small"
          />
       </TableCell>
       <TableCell >
        {btn}&nbsp;{settleBtn}
       </TableCell>
    </TableRow>    
  );
 
};

export default RoundOverviewRow;
