import React, { useState } from 'react';
import { TeamDetail } from '../RoundOverview/RoundOverviewModels';
import { getShirtByColors, ShirtColors, toShirtColors } from '../common/helpers/ShirtHelper';
import TeamDetailDialog from '../RoundOverview/TeamDetailDialog';
import RoundOverviewUtil from '../RoundOverview/RoundOverviewUtil';
import SpanClickView from './SpanClickView';

interface ShirtViewProps {
    teamId: number;
    teamName: string;
    teamDetail?: TeamDetail;
    disabled?: boolean;
    onTeamDetailChange?: (teamDetail?: TeamDetail) => void;
}

const ShirtView: React.FC<ShirtViewProps> = (props) => {
    const [teamDetailOpen, setTeamDetailOpen] = useState<string>("");
  
    const onTeamClick = (team: string) => {
      //alert(`onTeamClick(home:${home})`);
      setTeamDetailOpen(team);
    }
    const onTeamDetailClose = (teamDetail: TeamDetail | undefined) : void => {
      if (teamDetailOpen.length > 0) {
        setTeamDetailOpen("");
      }
      if (!teamDetail) {
        return;
      }
      if (props.onTeamDetailChange) {
        props.onTeamDetailChange(teamDetail);
      }
      setTeamDetailOpen("X");
    }
    
  
    var teamDetail = props.teamDetail;
    var teamColors : ShirtColors = teamDetail ? toShirtColors(teamDetail.primaryColor, teamDetail.kitColors) : toShirtColors(null, null);
    var teamShirt = getShirtByColors(teamColors, 0.5);
      
    var teamName = props.teamName;
    var homeTeamTitle = teamColors.type === "KitColors4" ? teamName : `${teamName} (*${teamColors.type}*)`;
    var teamDetailDialog = null;
    if (!props.disabled && teamDetailOpen && teamDetailOpen.length > 0 && !teamDetailOpen.endsWith("X")) {
       if (props.teamId > 0) {
        teamDetailDialog = (
          <TeamDetailDialog open={true} teamId={props.teamId} teamName={props.teamName} timestamp={RoundOverviewUtil.getTimestamp()} teamDetail={teamDetail} onClose={onTeamDetailClose} />
        );
      }
    }
    return (
        <>
            <SpanClickView tag='Home' item={teamShirt} tooltip={homeTeamTitle} onClick={k => onTeamClick(k)} disabled={props.disabled} warning={teamColors.type !== "KitColors4"} />
            {teamDetailDialog}
        </>
    );
  };
  
  export default ShirtView;
  
  
  
  