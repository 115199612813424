// in src/Menu.js
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, useTranslate } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import inflection from 'inflection';
import NestedMenu from "./NestedMenu";
import { useLocation } from 'react-router-dom';
import find from 'lodash/find';

const translatedResourceName = (resource, translate) =>
    translate(`resources.${resource.name}.name`, {
        smart_count: 2,
        _:
            resource.options && resource.options.label
                ? translate(resource.options.label, {
                    smart_count: 2,
                    _: resource.options.label,
                })
                : inflection.humanize(inflection.pluralize(resource.name)),
    });



const Menu = ({ onMenuClick, logout, ...rest }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const translate = useTranslate();
    const location = useLocation();

    const MenuResource = ({resource, resourceName}) => {
        if (!resource) {
            resource = resources.filter(r => r.name === resourceName)[0]
        }
        if (!resource) {
            return null
        }
        return <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={translatedResourceName(
                resource,
                translate
            )}
            leftIcon={
                resource.icon ? <resource.icon /> : <DefaultIcon />
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
        />
    }

    const NestedMenuWithResources = ({title, resourceNames, open}) => {
        const initialOpen = open || find(resourceNames, n => location.pathname === `/${n}`) !== undefined
        return <NestedMenu leftIcon={<DefaultIcon/>} title={title} initialOpen={initialOpen}>
            {resourceNames.map(name => resources.filter(r => r.name === name)[0]).filter(r => r !== undefined).map(resource => <MenuResource
                key={resource.name} resource={resource}/>)}
        </NestedMenu>;
    }
    return (
        <div>
            <MenuItemLink
            key="ticker"
            to={`/ticker`}
            primaryText="Ticker"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
        />
           <MenuItemLink
            key="reports"
            to={`/reports`}
            primaryText="Reports"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
        />
            <MenuItemLink
            key="createRound"
            to={`/createRound`}
            primaryText="Create Round"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
        />
            <MenuItemLink
            key="roundOverview"
            to={`/roundOverview`}
            primaryText="Round Overview"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
        />
            <MenuItemLink
            key="createFixture"
            to={`/createFixture`}
            primaryText="Create Fixture"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
        />
        <MenuItemLink
            key="leagueOverview"
            to={`/leagueOverview`}
            primaryText="League Overview"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
        /> 
        <MenuItemLink
            key="teamOverview"
            to={`/teamOverview`}
            primaryText="Team Overview"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
        /> 
        <MenuItemLink
            key="agentOverview"
            to={`/agentOverview`}
            primaryText="Agent Overview"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
        /> 
        <NestedMenu title="Freebets" leftIcon={<DefaultIcon />}>
            <MenuItemLink
                key="freebetOverview"
                to={`/freebetOverview`}
                primaryText="Freebet Overview"
                leftIcon={<DefaultIcon />}            
                onClick={onMenuClick}
                sidebarIsOpen={open}
            /> 
            <MenuItemLink
                key="freebetTypes"
                to={`/freebetTypes`}
                primaryText="Freebet Types"
                leftIcon={<DefaultIcon />}            
                onClick={onMenuClick}
                sidebarIsOpen={open}
            /> 
        </NestedMenu> 
        <NestedMenu title="New Admin" leftIcon={<DefaultIcon />}>
            <MenuItemLink
            key="operators"
            to={`/operators`}
            primaryText="Operators"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
            /> 
            <MenuItemLink
            key="operatorSettings"
            to={`/operatorSettings`}
            primaryText="Operator Settings"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
            /> 
            <MenuItemLink
            key="settings"
            to={`/settings`}
            primaryText="Settings"
            leftIcon={<DefaultIcon />}            
            onClick={onMenuClick}
            sidebarIsOpen={open}
            /> 
        </NestedMenu> 
            <NestedMenuWithResources title={'Rounds'} resourceNames={['rounds', 'roundTypes', 'draws', 'coupons', 'couponShares', 'accountTransactions', 'syndicates', 'syndicateShares', 'reducedSystems', 'settlements', 'roundTypeRolloverFunds']} open={true}/>
            <NestedMenuWithResources title={'Games'} resourceNames={['fixtures', 'teams', 'leagues', 'seasons', 'gameRounds']} open={true}/>
            <MenuResource resourceName={'players'}/>
            <MenuResource resourceName={'agents'}/>
            <NestedMenuWithResources title={'Admin'} resourceNames={['users', 'auditLogs', 'transactionalRequests']} open={true}/>
            <NestedMenuWithResources title={'Mock Operator'} resourceNames={['mockOpPlayers']} open={true}/>
            {isXSmall && logout}
        </div>
    );
};

export default Menu;