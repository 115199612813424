import { GenericResponse } from "../CreateRound/CreateRoundModels";
import ApiNgUtil from "../api/ApiNgUtil";
import { ListResponse } from "../api/ApiNgModels";
import InvokeApiMethods from "../ExternalApi/ExternalApiModels";
import AgentUtil from "../AgentOverview/AgentUtil";
import { Fixture, FixtureFilter, TeamOverviewFilter, TeamOverviewItem } from "./TeamOverviewModels";

class TeamOverviewUtil {
    static async getTeamOverviews(pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number, filter?: TeamOverviewFilter) : Promise<GenericResponse<ListResponse<TeamOverviewItem>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, filter);
        var url = "teams/overviews?" + urlParams;
        var response = await ApiNgUtil.getListByUrl<TeamOverviewItem>(url, "teamOverviews");
        if (!response.item?.items) {
            return response;
        }
        for (var item of response.item.items) {
            var detail = item.detail;
            if (detail) {
                if (detail.kitColors) {
                    if (detail.kitColors.length == 4) {
                        item.shirtsOrder = 2;
                    } else if (detail.kitColors.length == 11) {
                        item.shirtsOrder = 1;
                    }
                }
            } else {
                item.shirtsOrder = -100;
            }

            var nextFixture = item.nextFixture;
            item.fixtureTeamShirtsOrder = TeamOverviewUtil.getFixtureShirtsOrder(nextFixture);
            if (!nextFixture) {
                item.nextFixtureOrder = "";
                continue;
            }
            item.nextFixtureStartingAt = new Date(nextFixture.startingAt);
            item.nextFixtureOrder = `${item.nextFixtureStartingAt.toISOString()}|${nextFixture.name}`;
        }
        return response;
    }

    static async getTeamFixtures(teamId: number, pageSize: number, from: Date| undefined, to: Date| undefined, lastId: number, filter?: FixtureFilter) : Promise<GenericResponse<ListResponse<Fixture>>> {
        var urlParams = AgentUtil.getUrlParams(pageSize, from, to, lastId, filter);
        var url = `teams/${teamId}/fixtures?${urlParams}`;
        var response = await ApiNgUtil.getListByUrl<Fixture>(url, "fixtures");
        if (!response.item?.items) {
            return response;
        }
        for (var item of response.item.items) {
            item.score = `${item.homeTeam?.score}-${item.awayTeam?.score}`;
            item.shirtsOrder = TeamOverviewUtil.getFixtureShirtsOrder(item);
        }
        return response;
    }
    static getFixtureShirtsOrder(fixture: Fixture | undefined | null) : number {
        if (!fixture) {
            return -100;
        }
        var f : Fixture = fixture;
        var homeDetail = f.homeTeam?.detail;
        var awayDetail = f.awayTeam?.detail;
        var detailCount = (homeDetail ? 1 : 0) + (awayDetail ? 1 : 0);
        if (detailCount < 2) {
            return detailCount - 2;
        }
        var kitColors4Count = 0;
        if (homeDetail?.kitColors) {
            if (homeDetail.kitColors.length === 4) {
                kitColors4Count++;
            }
        }
        if (awayDetail?.kitColors) {
            if (awayDetail.kitColors.length === 4) {
                kitColors4Count++;
            }
        }
        return kitColors4Count;

    }
}
export default TeamOverviewUtil;