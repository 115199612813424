import React, { useState } from 'react';
import { FixtureTeam } from './TeamOverviewModels';
import { TeamDetail } from '../RoundOverview/RoundOverviewModels';
import ShirtView from '../Components/ShirtView';

interface FixtureTeamShirtViewProps {
    item: FixtureTeam;
    onTeamDetailChange: (detail: TeamDetail) => void;
  }
  const FixtureTeamShirtView: React.FC<FixtureTeamShirtViewProps> = (props) => {
    const [changeNo, setChangeNo] = useState<number>(0);
    var item = props.item;
    const onTeamDetailChange = (detail? : TeamDetail) => {
      if (detail) {
        item.detail = detail;
        setChangeNo(changeNo + 1);
        if (props.onTeamDetailChange) {
          props.onTeamDetailChange(detail);
        }
      }
    };
    return (
      <ShirtView teamId={item.id} teamName={item.name} teamDetail={item.detail} onTeamDetailChange={onTeamDetailChange} />
    );
  }
  export default FixtureTeamShirtView;
