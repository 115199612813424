import React, { useEffect, useState } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { Container, Tab, Tabs, Box, Button, TextField, FormControlLabel, Checkbox, Grid, FormGroup } from "@material-ui/core";
import CreateRoundUtil from "../CreateRound/CreateRoundUtil";
import { TeamOverviewFilter, TeamOverviewItem } from "./TeamOverviewModels";
import TeamOverviewUtil from "./TeamOverviewUtil";
import { BaseItemState, OverviewCriteria } from "../Ticker2/Ticker2Models";
import { GenericResponse, LeagueListItem } from "../CreateRound/CreateRoundModels";
import { ListResponse } from "../api/ApiNgModels";
import { Title } from "react-admin";
import RoundOverviewUtil from "../RoundOverview/RoundOverviewUtil";
import Ticker2Util from "../Ticker2/Ticker2Util";
import ChooseLeagueDialog from "../RoundOverview/ChooseLeagueDialog";
import TeamOverviewTable from "./TeamOverviewTable";
import { TabPanel } from "../Components/TabPanel";
import TeamFixtureView from "./TeamFixtureView";

interface TeamOverviewState {
    namePattern: string;
    leagueId: number;
    leagueName: string;
    anyNextFixtureLeague: boolean;
    fixturePeriodEnabled: boolean;
    fromInput: string;
    toInput: string;
    limitInput: string;
    from?: Date;
    to?: Date;
    limit?: number;
    valid: boolean;
    namePatternError: string;
    fromError: string;
    toError: string;
    limitError: string;
}
interface TeamOverviewItemState extends BaseItemState<TeamOverviewItem, number> {
}
function checkFormState(fs: TeamOverviewState)  {
    var valid = true;
    var from = CreateRoundUtil.parseDateX(fs.fromInput);
    var to = CreateRoundUtil.parseDateX(fs.toInput);
    var limit = parseInt(fs.limitInput);
    var dateTime = from;
    var namePatternErr = "";
    var fromErr = "";
    var toErr = "";
    var limitErr = "";
    if (fs.fromInput.length > 0) {
        var dateNumber = from.getDate();
        if (isNaN(dateNumber)) {
            valid = false;
            fromErr = "Invalid date";
        } else {
            fs.from = from;
        }
    }
    else {
        valid = false;
        fromErr = "Required";
    }
    if (fs.toInput.length > 0) {
        var dateNumber = to.getDate();
        if (isNaN(dateNumber)) {
            valid = false;
            fromErr = "Invalid date";
        } else {
            fs.to = to;
        }
    }
    else {
        valid = false;
        fromErr = "Required";
    }
    if (fs.limitInput.length > 0) {
        if (isNaN(limit)) {
            valid = false;
            limitErr = "Invalid integer";
        } else if (limit < 1 || limit > 100) {
            valid = false;
            limitErr = "Integer in range 1-100";
        } else {
            fs.limit = limit;
        }
    }
    else {
        valid = false;
        limitErr = "Required";
    }

    fs.valid = valid;
    fs.namePatternError = namePatternErr;
    fs.fromError = fromErr;
    fs.toError = toErr;
    fs.limitError = limitErr;
}

function getInitialState() : TeamOverviewState {
    var s = sessionStorage.getItem("TeamOverviewState");
    var fs : TeamOverviewState | undefined = undefined;
    if (s && s.length > 0) {
        try {
            var fs1: TeamOverviewState = JSON.parse(s);
            if (fs1) {
                checkFormState(fs1);
                if (fs1.valid) {
                    fs = fs1;
                }
            }
        } catch {

        }
    }
    if (!fs) {
        fs = {
            namePattern: "",
            leagueId: 0,
            leagueName: "",
            anyNextFixtureLeague: false,
            fixturePeriodEnabled: true,
            fromInput: CreateRoundUtil.getDateWithoutTimeString(new Date()),
            toInput: CreateRoundUtil.getDateWithoutTimeString(CreateRoundUtil.addDays(new Date(), 14)),
            limitInput: "100",
            from: CreateRoundUtil.getDateWithoutTime(new Date()),
            to: CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), 14)),
            limit: 100,
            valid: true,
            namePatternError: "",
            fromError: "",
            toError: "",
            limitError: ""
        };
    }
    return fs;
};

var initialState = getInitialState();

function createCriteria(state: TeamOverviewState, createNo: number) : OverviewCriteria {
    var fs = state;
    return {
        pageSize: fs.limit ? fs.limit : 100,
        from:  fs.from ? fs.from : CreateRoundUtil.getDateWithoutTime(new Date()),
        to: fs.to ? fs.to : CreateRoundUtil.getDateWithoutTime(CreateRoundUtil.addDays(new Date(), 14)),
        createNo: createNo
    }
};
function getInitialCriteria() : OverviewCriteria {
    return createCriteria(initialState, 1);
};
var initialCriteria = getInitialCriteria();


const TeamOverview: React.FC = () => {
    const [formState, setFormState] = useState<TeamOverviewState>(initialState);
    const [criteria, setCriteria] = useState<OverviewCriteria>(initialCriteria);
    const [itemState, setItemState] = useState<TeamOverviewItemState>({
        items: [],
        more: false,
        lastId: 0,
        refreshing: false
    });

    const [actionError, setActionError] = useState<string>("");
    const [selectedItem, setSelectedItem] = useState<TeamOverviewItem | undefined>(undefined);
    const [selectedTab, setSelectedTab] = useState(() => 0)
    const history = useHistory();


    useEffect(() => {
        search("Init", formState);
    }, []);

    const handleChange = (name: string, value:any) =>{
        var fs = {...formState, [name]: value};
        checkFormState(fs);
        setFormState(fs);
    }

    const toFilter = (fs: TeamOverviewState) : TeamOverviewFilter | undefined => {
        var count = 0;
        var filter : TeamOverviewFilter = {};
        if (fs.namePattern.trim().length > 0) {
            filter.namePattern = fs.namePattern;
            count++;
        }
        if (fs.leagueId > 0) {
            filter.leagueId = fs.leagueId;
            count++;
        }
        filter.anyNextFixtureLeague = fs.anyNextFixtureLeague;
        count++;
        if (count > 0) {
            return filter;
        }
        return undefined;
    };
    const search = (caller: string, fs: TeamOverviewState) : void => {
        setActionError("");
        var filter = toFilter(fs);
        var crit : OverviewCriteria = {
            pageSize: formState.limit ? formState.limit : criteria.pageSize,
            from: formState.from ? formState.from : criteria.from,
            to: formState.to ? formState.to : criteria.to,
            createNo: criteria.createNo + 1
        };
        if (caller === "Search") {
            initialState = formState;
            initialCriteria = crit;    
        }
        setCriteria(crit);
        var rs : TeamOverviewItemState = {
            items:[],
            more: false,
            lastId: 0,
            refreshing: false
        };
        var from : Date | undefined = formState.fixturePeriodEnabled ? crit.from : undefined;
        var to : Date | undefined = formState.fixturePeriodEnabled ? crit.to : undefined;
        itemState.refreshing = true;
        TeamOverviewUtil.getTeamOverviews(crit.pageSize, from, to, 0, filter).then((resp) => {
            if (!resp.success) {
                setActionError(`${caller} error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    rs.items = listResponse.items;
                    if (listResponse.items.length > 0) {
                        rs.lastId = listResponse.items[listResponse.items.length - 1].id;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        rs.more = true;
                    }
                }           
            }
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, `${caller} error`));
        }).finally( () => {
            itemState.refreshing = false;
            setItemState(rs);
        });
    };

    const onSearch = () : void => {
        search("Search", formState);
        sessionStorage.setItem("TeamOverviewState", JSON.stringify(formState));
    };

    const getMore = async (fs: TeamOverviewState) : Promise<GenericResponse<ListResponse<TeamOverviewItem>>> => {
        const METHOD_NAME = "getMore";
        try {
            var filter = toFilter(fs);
            const lastId = itemState ? itemState.lastId : 0;
            if (lastId <= 0) {
                return CreateRoundUtil.createGenericResponse<ListResponse<TeamOverviewItem>>(false, "LastId not specified.", null);
            }
            return TeamOverviewUtil.getTeamOverviews(criteria.pageSize, criteria.from, criteria.to, lastId, filter);

        } catch (error) {
            console.error(`${METHOD_NAME} error.`, error);
            var message = CreateRoundUtil.getExceptionMessage(error, false, METHOD_NAME + " error");
            return CreateRoundUtil.createGenericResponse<ListResponse<TeamOverviewItem>>(false, message, null);
        }
    }
    const onMore = () : void => {
        if (!itemState || itemState.lastId <= 0) {
            setActionError("More failure: LastId was not set");
            return;
        }
        setActionError("");
        var ts : TeamOverviewItemState = {
            items: itemState ? itemState.items : [],
            more: false,
            lastId: 0,
            refreshing: false
        }
        if (itemState) {
            itemState.refreshing = true;
        }
        getMore(formState).then(resp => {
            if (!resp.success) {
                setActionError(`More.getMore error: ${resp.message}`);
                return;
            }
            var listResponse = resp.item;
            if (listResponse) {
                if (listResponse.items) {
                    for (const item of listResponse.items) {
                        ts.items.push(item);
                    }               
                    if (ts.items.length > 0) {
                        ts.lastId = ts.items[ts.items.length - 1].id;
                    }
                    if (listResponse.pageSize <= listResponse.items.length) {
                        ts.more = true;
                    }
                }           
            }
            setItemState(ts);
        }).catch(error => {
            setActionError(CreateRoundUtil.getExceptionMessage(error, false, "More.getMore error (2)"));
        }).finally(() => {
            itemState.refreshing = false;
        });
    }

    const onSelect = (item: TeamOverviewItem) => {
        setSelectedItem(item);
    };
    const onDeselect = () => {
        setSelectedItem(undefined);
    };
    const onCreateTeam = () => {
        history.push('editTeam');
    };
    const onCloseLeagueDlg = (league?: LeagueListItem) => {
        if (!league) {
            return;
        }
        var fs = { ...formState, leagueId: parseInt(league.id) };
        fs.leagueName = league.title;
        setFormState(fs);
    };
    const onClearLeague = () => {
        if (formState.leagueId === 0) {
            return;
        }
        var fs = { ...formState, leagueId: 0 };
        fs.leagueName = "";
        setFormState(fs);
    };

    var actionErrorBox = null;
    if (actionError.length > 0) {
        actionErrorBox = (<Box color={RoundOverviewUtil.INVALID_COLOR} fontSize={10}>{actionError}</Box>)
    }
    var summaryBox = null;
    var items = itemState.items;
    var info = Ticker2Util.toItemStateString(itemState, criteria);
    var count = items.length;
    var summaryText = `Summary - Count: ${count}`;
    summaryBox = (<Box fontSize={12}>{summaryText}</Box>)

    var moreBtn = null;
    if (itemState.more && !itemState.refreshing) {
        moreBtn = (<Button 
            variant="text"
            color="secondary"
            size="small"
            disabled={!formState.valid || itemState.refreshing}
            onClick={e => onMore()}>
            More
        </Button>);
    }

    var teamInfoBox = null;
    if (selectedItem && selectedItem.id > 0) {
        var teamInfoText = `Team: ${selectedItem.name}`;
        teamInfoBox = (<Box fontSize={12}>{teamInfoText}</Box>);
    }
    var gridStyleMargin = "20px";
    var gridStyle : React.CSSProperties = { marginLeft: "20px" };
    return (
        <Container maxWidth="xl">
            <Title title="Team Overview" />
            <Grid container alignItems='center' justifyContent='flex-start' style={{ marginTop: '5px' }} spacing={2}>
                <Grid style={gridStyle}>
                    <Button 
                        component={RouterLink}
                        variant="contained"
                        color="secondary"
                        size="small"
                        to={"/teams/create"}>
                        Create team
                    </Button>
                </Grid>
                <Grid style={gridStyle}>
                    <TextField
                        name="namePattern"
                        value={formState.namePattern}
                        label="Name pattern"
                        InputLabelProps={{shrink: true}}
                        error={formState.namePatternError && formState.namePatternError.length > 0 ? true : false}
                        helperText={formState.namePatternError}
                        onChange={e => handleChange("namePattern", e.target.value)}
                        style={{ marginLeft:5 }}
                    />
                </Grid>
                <Grid style={gridStyle}>
                    <Grid container wrap='nowrap' alignItems='center' justifyContent='flex-start' style={{ marginTop: '5px' }} spacing={2}>
                    <Grid>
                    <TextField
                        name="leagueName"
                        value={formState.leagueName}
                        label="League"
                        InputLabelProps={{shrink: true}}
                        InputProps={{ readOnly: true }}
                        style={{minWidth:350, marginLeft:5}}
                    />
                </Grid>
                <Grid>
                    <ChooseLeagueDialog open={false} openLabel="Change" onClose={onCloseLeagueDlg} />
                    </Grid>
                <Grid>
                    <Button 
                        variant="outlined"
                        color="secondary"
                        size="small"
                        style={{ marginLeft:5, marginRight:5 }}
                        onClick={e => onClearLeague()}>
                        Clear
                    </Button>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container alignItems='center' justifyContent='flex-start' style={{ marginTop: '20px', marginBottom: "20px" }} spacing={2}>
                <Grid style={gridStyle}>
                <TextField
                    type="date"
                    name="from"
                    value={formState.fromInput}
                    label="From"
                    InputLabelProps={{shrink: true}}
                    error={formState.fromError && formState.fromError.length > 0 ? true : false}
                    helperText={formState.fromError}
                    onChange={e => handleChange("fromInput", e.target.value)}
                    style={{ marginLeft:2 }}
                />
                </Grid>
                <Grid style={gridStyle}>
                <TextField
                    type="date"
                    name="to"
                    value={formState.toInput}
                    label="To"
                    InputLabelProps={{shrink: true}}
                    error={formState.toError && formState.toError.length > 0 ? true : false}
                    helperText={formState.toError}
                    onChange={e => handleChange("toInput", e.target.value)}
                    style={{ marginLeft:2 }}
                />
                </Grid>
                <Grid style={gridStyle}>
                <TextField
                    name="limit"
                    label="Page size"
                    type="number"
                    value={formState.limitInput}
                    style={{ width: 100 }}
                    InputLabelProps={{shrink: true}}
                    error={formState.limitError && formState.limitError.length ? true : false}
                    helperText={formState.limitError}
                    onChange={e => handleChange("limitInput", e.target.value)}
                />
                </Grid>
                <Grid style={gridStyle}>
                    <FormGroup>
                    <FormControlLabel style={{padding: 0}} control={<Checkbox checked={formState.anyNextFixtureLeague} name="anyNextFixtureLeague" onChange={e => handleChange("anyNextFixtureLeague", e.target.checked)} style={{padding: 0}} />} label="Any Next fixture league" />                  
                    <FormControlLabel control={<Checkbox checked={formState.fixturePeriodEnabled} name="fixturePeriodEnabled" onChange={e => handleChange("fixturePeriodEnabled", e.target.checked)} style={{padding: 0}} />} label="Fixture period enabled" />
                    </FormGroup>
                </Grid>
                <Grid style={gridStyle}>
                <Button 
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={!formState.valid}
                    onClick={e => onSearch()}>
                    Search
                </Button>
                </Grid>
            </Grid>
            {actionErrorBox}
            <Box fontSize={10}>
                {info}{moreBtn}
            </Box>
            {summaryBox}
            <TeamOverviewTable items={items} onSelect={onSelect} onDeselect={onDeselect} />
            {teamInfoBox}
            <div>
                <Tabs value={selectedTab}
                    onChange={(ev, value) => { setSelectedTab(value) }}
                >
                    <Tab label="Fixtures" />
                    <Tab label="Rounds" />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    <TeamFixtureView team={selectedItem} criteria={criteria} leagueId={formState.leagueId} />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    Rounds go here
                </TabPanel>
            </div>

        </Container>
    );
};

export default TeamOverview;
