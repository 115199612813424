import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Link, Paper, Size, Table, TableBody, TableCell, TableCellProps, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField } from '@material-ui/core';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { bool } from 'prop-types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any}, b: { [key in Key]: any}) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export function sortDataTableItems<T, Key extends keyof T>(items: T[], order: Order, orderBy: Key) {
  var comparator = getComparator(order, orderBy);
  const stabilizedThis = items.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export function getDataTablePage<T, Key extends keyof T>(items: T[], order: Order, orderBy: Key, page: number, rowsPerPage: number) {
  var sortedItems = sortDataTableItems(items, order, orderBy);
  var pageItems = sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return pageItems;
}

export function filterItemsByKeys<T, Key extends keyof T>(items: T[], filterKeys: Key[], filter: string ) {
  if (!items || items.length <= 0 || !filterKeys || filterKeys.length <= 0 || !filter || filter.length <= 0) {
    return items;
  }
  var filterRegex = new RegExp(filter, "i");
  var items2 : T[] = [];
  items.forEach(it => {
      for (var iCol = 0; iCol < filterKeys.length; iCol++) {
        var col = filterKeys[iCol];
        var colVal : string | undefined = it[col]?.toString();
        if (!colVal) {
          continue;
        }
        if (colVal.indexOf(filter) >= 0) {
          items2.push(it);
          break;
        }
        if (filterRegex.test(colVal)) {
          items2.push(it);
          break;
        }
      }
  });
  return items2;
}
export function filterDataTableItems<T>(items: T[], columns: DataTableColumn<T>[], filter: string ) {
  if (!items || items.length <= 0 || !columns || columns.length <= 0 || !filter || filter.length <= 0) {
    return items;
  }
  var filterColumns = columns.filter(it => it.filterEnabled);
  if (!filterColumns || filterColumns.length <= 0) {
    return items;
  }
  var filterRegex = new RegExp(filter, "i");
  var items2 : T[] = [];
  items.forEach(it => {
      for (var iCol = 0; iCol < filterColumns.length; iCol++) {
        var col = filterColumns[iCol];
        var colVal : string | undefined = col.id ? it[col.id]?.toString() : undefined;
        if (!colVal) {
          continue;
        }
        if (colVal.indexOf(filter) >= 0) {
          items2.push(it);
          break;
        }
        if (filterRegex.test(colVal)) {
          items2.push(it);
          break;
        }
      }
  });
  return items2;
}
  
// const useStyles = makeStyles({
//     table: {
//       minWidth: 650,
//     },
// });
export const useDataTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750
    },
    xSmallTable: {
      minWidth: 750,
      "& .MuiTableCell-sizeSmall": {
        padding: "3px 6px 3px 3px" // <-- arbitrary value
      }
    },
    xSmallTablePagination: {
      "& .MuiTablePagination-toolbar": {
        minHeight: "26px",
        paddingRight: "2px"
      }
    },     
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableRow: {
      "&:hover":{
        cursor: "pointer"
      }
    },
    tableHead: {
      padding: "none",
    },
    tableCell: {
      padding: 0,
    }
  }),
);

export interface DataTableColumn<T> {
  disablePadding: boolean;
  id?: keyof T;
  label: string;
  numeric: boolean;
  filterEnabled?: boolean;
  visible?:boolean;
  renderValue?: (item: T) => any;
  width?: string | number;
}
export type Order = 'asc' | 'desc';
type DataTableSize = 'medium' | 'small' | 'xsmall';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "lightgrey",
    color: theme.palette.common.black,
  }
}))(TableCell);

interface DataRowProps<T, TKey> {
  columns: DataTableColumn<T>[]
  item: T;
  isSelected: boolean;
  onSelect : (item : T) => void;
}
const DataRow = <T, TKey>(props: DataRowProps<T, TKey>) : JSX.Element => {
  var row = props.item;
  var classes = useDataTableStyles();
  const renderValue = (column: DataTableColumn<T>, item: T) : any => {
    if (!column.id) {
      return null;
    }
    var rawValue = item[column.id];
    if (rawValue === undefined || rawValue === null) {
      return null;
    }
    if (typeof rawValue === 'boolean') {
      return rawValue ? "X" : "-";
    }
    var value = rawValue.toString();
    return value;
  }
  return (
    <TableRow 
    hover
    onClick={(e: any) => props.onSelect(row)}
    selected={props.isSelected}
    className={classes.tableRow}
  >
    {props.columns.map((column) => (
      <TableCell
        align={column.numeric ? 'right' : 'left'}
      >
        {column.renderValue ? column.renderValue(row) : renderValue(column, row)}
      </TableCell>
    ))}
  </TableRow>                    

  );
}

interface Paging {
  page: number;
  rowsPerPage: number;
}
export interface CreateDataRowContext<T> {
  tableRowClass: string;
  tableCellClass: string;
  isSelected: (item: T) => boolean;
  onClick: (item: T) => void;
}
interface DataTableProps<T, TKey> {
  columns: DataTableColumn<T>[]
  items: T[];
  getId: (item: T) => TKey;
  orderBy: keyof T;
  order: Order;
  size: DataTableSize;
  rowsPerPage?: number;
  resultFilterEnabled: boolean;
//  createRow?: (item: T, classes: ClassNameMap ) => JSX.Element;
  createRow?: (item: T, context: CreateDataRowContext<T> ) => JSX.Element;
  onSelect? : (item : T) => void;
  onDeselect?: () => void;
}

const DataTable = <T, TKey>(props: DataTableProps<T, TKey>) : JSX.Element => {
    const classes = useDataTableStyles();
    const [selectedItemId, setSelectedItemId] = React.useState<TKey | undefined>(undefined);
    const [orderBy, setOrderBy] = React.useState<keyof T>(props.orderBy);
    const [order, setOrder] = React.useState<Order>(props.order);
    //const [page, setPage] = React.useState<number>(0);
    const [paging, setPaging] = React.useState<Paging>({
      page: 0,
      rowsPerPage: props.rowsPerPage ? props.rowsPerPage : 10
    });
    const [dense, setDense] = React.useState(true);
    //const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage ? props.rowsPerPage : 10);
    const [resultFilter, setResultFilter] = useState<string>("");
    // const [createRowContext, setCreateRowContext] = useState<CreateDataRowContext<T>>({
    //   tableRowClass: classes.tableRow,
    //   tableCellClass: classes.tableCell,
    //   isSelected: (item: T) : boolean => { return (selectedItemId === props.getId(item)); },
    //   onClick: (item: T) => onSelect(item)
    // });
    const createRowContext : CreateDataRowContext<T> = {
      tableRowClass: classes.tableRow,
      tableCellClass: classes.tableCell,
      isSelected: (item: T) : boolean => { return (selectedItemId === props.getId(item)); },
      onClick: (item: T) => onSelect(item)
    };
    var columns = props.columns;
    
    const onSort = (property?: keyof T) => {
      if (!property) {
        return;
      }
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        //setPage(newPage);
        var p = {...paging, page: newPage};
        setPaging(p);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setRowsPerPage(parseInt(event.target.value, 10));
        //setPage(0);
        var p = {...paging, rowsPerPage: parseInt(event.target.value, 10)};
        setPaging(p);
      };
    
      const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
      };
    const onSelect = (item : T) => {
      var id = props.getId(item);
      if (id === selectedItemId) {
        //Unselect
        if (props.onDeselect) {
          setSelectedItemId(undefined);
          props.onDeselect();
          return;
        }
      }
      setSelectedItemId(id);
      if (props.onSelect){
        props.onSelect(item);
        return;
      }
    };
    
    const renderValue = (column: DataTableColumn<T>, item: T) : any => {
      if (!column.id) {
        return null;
      }
      var rawValue = item[column.id];
      if (rawValue === undefined || rawValue === null) {
        return null;
      }
      if (typeof rawValue === 'boolean') {
        return rawValue ? "X" : "-";
      }
      var value = rawValue.toString();
      return value;
    }
    var items = props.items;
    var resultFilterEnabled = props.resultFilterEnabled;
    if (resultFilterEnabled) {
      var firstFilterColumn = columns.find(it => it.filterEnabled);
      if (!firstFilterColumn) {
        //No filter columns, disable filter
        resultFilterEnabled = false;
      }
    }
    var resultFilterBox = null;
    if (resultFilterEnabled) {
      resultFilterBox = (<Box paddingBottom={2} paddingLeft={2}>
          <TextField
              name="resultFilter"
              label="Result filter"
              value={resultFilter}
              style={{ width: 200 }}
              onChange={(e) => setResultFilter(e.target.value)}
          />
        </Box>);
      if (resultFilter.length > 0) {
        items = filterDataTableItems(items, columns, resultFilter);
      }
    }
    var pageNo = paging.page;
    var rowsPerPage = paging.rowsPerPage;

    if (pageNo * rowsPerPage > items.length) {
      pageNo = Math.floor(items.length / rowsPerPage);
      paging.page = pageNo;
    }
    var pageItems = sortDataTableItems(items, order, orderBy).slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage);
    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
    const emptyRows = 0;
    var className = props.size === 'xsmall' && dense ? classes.xSmallTable : classes.table;
    var size : Size = "small";
    if (props.size === 'medium' || !dense) {
      size = 'medium'
    }
    var tablePaginationClass : string = className === classes.xSmallTable ? classes.xSmallTablePagination : "";

    var visibleColumns = columns;
    var firstNonVisibleColumn = columns.find(it => it.visible !== undefined && it.visible === false);
    if (firstNonVisibleColumn) {
      visibleColumns = columns.filter(it => it.visible === undefined || it.visible === true);
    }

    return (
      // <Box component='form' onSubmit={handleSubmit}>
      <Paper>
         {resultFilterBox}
      <TableContainer>
        <Table 
            className={className}
            aria-label="simple table"
            size={size}
        >
          <TableHead>
            <TableRow>
        {visibleColumns.map((column) => 
          column.id ? (
          <StyledTableCell
            key={column.id.toString()}
            align={column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.id ? order : false}
            width={column.width}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={(e) => onSort(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            align={column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            width={column.width}
          >
              {column.label}
          </StyledTableCell>
        ))}                    
            </TableRow>
          </TableHead>
          <TableBody>
            {pageItems.map((row) => 
              props.createRow ? props.createRow(row, createRowContext) : (
              <TableRow 
                hover
                onClick={(e: any) => onSelect(row)}
                selected={(selectedItemId === props.getId(row))}
                className={classes.tableRow}
              >
                {visibleColumns.map((column) => (
                  <TableCell
                    align={column.numeric ? 'right' : 'left'}
                  >
                    {column.renderValue ? column.renderValue(row) : renderValue(column, row)}
                  </TableCell>
                ))}
              </TableRow> 
              //<DataRow columns={visibleColumns} item={row} isSelected={selectedItemId === props.getId(row)} onSelect={onSelect} />                   
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={columns.length + 1} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={pageNo}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={tablePaginationClass}
      />
      </Paper>
  );
  };
  
  export default DataTable;
 