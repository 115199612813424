import React, { useEffect, useState } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem } from '@material-ui/core';
import { TeamDetail, TeamDetailUpdate } from "./RoundOverviewModels";
import { getShirtByColors, ShirtColors, toKitColors, toShirtColors } from "../common/helpers/ShirtHelper";
import RoundOverviewUtil from "./RoundOverviewUtil";

interface TeamDetailDialogProps {
    open: boolean;
    teamId: number;
    teamName: string;
    teamDetail?: TeamDetail;
    timestamp: number;
    onClose: (teamDetail: TeamDetail | undefined) => void;
}
interface TeamDetailDialogState {
    timestamp: number;
    id: number;
    teamId: number;
    teamName: string;
    item?: TeamDetail;
    primaryColor: string;
    shirtColors: ShirtColors;
    baseColor: string;
    stripesColor: string;
    armsColor: string;
    collarColor: string;
    valid: boolean;
    primaryColorError: string;
    baseColorError: string;
    stripesColorError: string;
    armsColorError: string;
    collarColorError: string;
    value?: TeamDetail;
    update?: TeamDetailUpdate;
}

const defaultShirtColors = toShirtColors(null, null);

interface CheckInputResult {
    valid: boolean;
    message: string;
}
function checkInput(input: string, required: boolean = true) : CheckInputResult {
    var valid = true;
    if (input.length === 0) {
        if (required) {
            return {valid: false, message: "Required"};
        }
    } else if (input.trim().length === 0) {
        return {valid: false, message: "Cannot be blank"};
    } else if (input.trim().length !== input.length) {
        return {valid: false, message: "Starts/ends with blanks"};
    }
    return {valid: true, message: ""};

}
function isHexcodeColor(color: string) : boolean {
    return /^#([A-Fa-f0-9]{6})$/i.test(color);
}

function checkColor(input: string, required: boolean = true) : CheckInputResult {
    var result = checkInput(input, required);
    if (!result.valid || input.length == 0) {
        return result;
    }
    var valid = isHexcodeColor(input);
    if (!valid) {
        result.valid = false;
        result.message = "Invalid Hexcode color";
    }
    return result;
}
function checkState(state : TeamDetailDialogState) {
    var valid = true;
    var validKitColors = true;
    var primaryColorErr = "";
    var baseColorErr = "";
    var stripesColorErr = "";
    var armsColorErr = "";
    var collarColorErr = "";

    var result = checkColor(state.primaryColor);
    if (!result.valid) {
        valid = false;
        primaryColorErr = result.message;
    }

    result = checkColor(state.baseColor);
    if (!result.valid) {
        valid = false;
        baseColorErr = result.message;
        validKitColors = false;
    }
    
    result = checkColor(state.stripesColor);
    if (!result.valid) {
        valid = false;
        stripesColorErr = result.message;
        validKitColors = false;
    }

    result = checkColor(state.armsColor);
    if (!result.valid) {
        valid = false;
        armsColorErr = result.message;
        validKitColors = false;
    }

    result = checkColor(state.collarColor);
    if (!result.valid) {
        valid = false;
        collarColorErr = result.message;
        validKitColors = false;
    }


    state.valid = valid;
    state.primaryColorError = primaryColorErr;
    state.baseColorError = baseColorErr;
    state.stripesColorError = stripesColorErr;
    state.armsColorError = armsColorErr;
    state.collarColorError = collarColorErr;

    if (validKitColors) {
        state.shirtColors = {
            type: "KitColors4",
            base: state.baseColor,
            stripes: state.stripesColor,
            arms: state.armsColor,
            collar: state.collarColor
        };
    } else {
        if (baseColorErr.length === 0) {
            state.shirtColors = toShirtColors(state.baseColor, null);
        } else if (primaryColorErr.length === 0) {
            state.shirtColors = toShirtColors(state.primaryColor, null);
        } else {
            state.shirtColors = toShirtColors(null, null);
        }

        if (stripesColorErr.length === 0) {
            state.shirtColors.stripes = state.stripesColor;
        }
        if (armsColorErr.length === 0) {
            state.shirtColors.arms = state.armsColor;
        }
        if (collarColorErr.length === 0) {
            state.shirtColors.collar = state.collarColor;
        }
    }

    if (valid) {
        var item = state.item;
        var teamDetail : TeamDetail = {
            id: item ? item.id : 0,
            teamId: state.teamId,
            teamName: state.teamName,
            primaryColor: state.primaryColor,
            kitColors: toKitColors(state.shirtColors)
        };
        state.value = teamDetail;

        var teamDetailUpdate : TeamDetailUpdate | undefined = { id: teamDetail.id, teamId: teamDetail.teamId };
        var changeCount = 0;
        if (item) {
            if (teamDetail.primaryColor !== item.primaryColor) {
                teamDetailUpdate.primaryColor = teamDetail.primaryColor;
                changeCount++;
            }
            if (!item.kitColors || item.kitColors.length !== teamDetail.kitColors.length) {
                teamDetailUpdate.kitColors = teamDetail.kitColors;
                changeCount++;
            } else {
                for (var i = 0; i < teamDetail.kitColors.length; i++) {
                    var itemColor = item.kitColors[i];
                    var newColor = teamDetail.kitColors[i];
                    if (itemColor !== newColor) {
                        teamDetailUpdate.kitColors = teamDetail.kitColors;
                        changeCount++;
                        break;
                    }
                }
            }

            if (changeCount <= 0) {
                teamDetailUpdate = undefined;
            }
        } else {
            teamDetailUpdate.primaryColor = teamDetail.primaryColor;
            teamDetailUpdate.kitColors = teamDetail.kitColors;
        }
        state.update = teamDetailUpdate;
    } else {
        state.value = undefined;
        state.update = undefined;
    }
}

function updateState(state: TeamDetailDialogState, item: TeamDetail) {
    //state.updateNo += 1;
    state.item = item;
    state.id = item.id;
    state.teamId = item.teamId;
    state.teamName = item.teamName;
    state.primaryColor = item.primaryColor ? item.primaryColor : "";
    var shirtColors = toShirtColors(item.primaryColor, item.kitColors);
    if (shirtColors.type === "KitColors4" || shirtColors.type === "KitColors11") {
        state.baseColor = shirtColors.base;
        state.stripesColor = shirtColors.stripes;
        state.armsColor = shirtColors.arms;
        state.collarColor = shirtColors.collar;
    } else {
        state.baseColor = "";
        state.stripesColor = "";
        state.armsColor = "";
        state.collarColor = "";
    }
    checkState(state);
}

const TeamDetailDialog: React.FC<TeamDetailDialogProps> = (props) => {
    const getDefaultFormState = () : TeamDetailDialogState => {
        var fs : TeamDetailDialogState = {
            timestamp: props.timestamp,
            id: 0,
            item: props.teamDetail,
            teamId: props.teamId,
            teamName: props.teamName,
            primaryColor: "",
            shirtColors: defaultShirtColors,
            baseColor: "",
            stripesColor: "",
            armsColor: "",
            collarColor: "",
            valid: false,
            primaryColorError: "Required",
            baseColorError: "Required",
            stripesColorError: "Required",
            armsColorError: "Required",
            collarColorError: "Required"
        };
        if (props.teamDetail) {
            updateState(fs, props.teamDetail);
        } else {
            checkState(fs);
        }
        return fs;
    }
    
    const [open, setOpen] = useState<boolean>(props.open);
    const [formState, setFormState] = useState<TeamDetailDialogState>({
            timestamp: 0,
            id: 0,
            item: props.teamDetail,
            teamId: props.teamId,
            teamName: props.teamName,
            primaryColor: "",
            shirtColors: defaultShirtColors,
            baseColor: "",
            stripesColor: "",
            armsColor: "",
            collarColor: "",
            valid: false,
            primaryColorError: "Required",
            baseColorError: "Required",
            stripesColorError: "Required",
            armsColorError: "Required",
            collarColorError: "Required"
        }
    );
    const [teamDetail, setTeamDetail] = useState<TeamDetail | null | undefined>(props.teamDetail);
    const [saveError, setSaveError] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<number>(0);
    var teamDetailId = props.teamDetail ? props.teamDetail.id : 0;
    useEffect(() => {
        setFormState(getDefaultFormState());
    }, [props.timestamp, props.teamId]);

    const handleClickOpen = () => {
        setOpen(true);
        setFormState(getDefaultFormState());
    };

    const handleDialogClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) : void => {
        e.stopPropagation();
    }; 

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose(undefined);
        }
    };
    const handleChange = (name: string, value:string) =>{
        //var prevValue = formState.[name];
        var fs = {...formState, [name]: value};
        checkState(fs);
        setFormState(fs);
    }

    const onSave = () => {
        const METHOD_NAME = "SaveTeamDetail";

        setSaveError("");
        setSaveStatus(0);
        if (!formState.valid) {
            setSaveError("Input is not valid");
            return;
        }
        if (!formState.update) {
            setSaveError("No changes");
            return;
        }
        setSaveStatus(1);
        var saveJson = JSON.stringify(formState.update);
        RoundOverviewUtil.saveTeamDetail(formState.id, saveJson).then(resp => {
            setSaveStatus(0);
            if (resp.success){
                var item = resp.item;
                if (item) {
                    setOpen(false);
                    if (props.onClose) {
                        props.onClose(item);
                    }
                    return;

                } 
                setSaveError(`${METHOD_NAME} failure: TeamDetail was not returned.`);

            } else {
                setSaveError(resp.message);
            }
        }).catch(error => {
            setSaveError(`${METHOD_NAME} error: ${error}`);
            setSaveStatus(0);
        });           
    };
    var saveEnabled = (formState.valid && saveStatus === 0 && formState.update);
    var saveErrorBox = null;
    if (saveError.length > 0) {
        saveErrorBox = (<Box color="red">{saveError}</Box>);
    }
    // var openButton = null;
    // if (props.nameLinkEnabled) {
    //     openButton = (<Link underline="hover" onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
    //             {props.fixture.name}
    //         </Link>);
    // } else {
    //     openButton = (<Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
    //                 Fixture
    //         </Button>);

    // }
    var shirt = getShirtByColors(formState.shirtColors);
    return (
        <div>
            {props.open ? null : (
                <Button onClick={handleClickOpen} variant="outlined" color="secondary" size="small">
                    Team Detail
                </Button>
            )}
        <Dialog
            open={open}
            onClose={handleClose}
            onClick={e => handleDialogClick(e)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Team Detail</DialogTitle>
            <DialogContent dividers>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText> */}
                <Box display="flex" alignItems="center">
                    <TextField
                        label="Team Detail"
                        name="id"
                        value={formState.id}
                        helperText={`Team: ${formState.teamName} (${formState.teamId})`}
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                </Box>
                <Box marginTop={1} display="flex" alignItems="center">
                <TextField
                    autoFocus
                    name="primaryColor"
                    value={formState.primaryColor}
                    label="Primary color"
                    onChange={e => handleChange("primaryColor", e.target.value)}
                    helperText={formState.primaryColorError}
                    error={formState.primaryColorError.length > 0}
                    margin="dense"
                    />
                </Box>
                <Box marginTop={1} display="flex" alignItems="center">
                    <TextField
                        name="baseColor"
                        value={formState.baseColor}
                        label="Base"
                        onChange={e => handleChange("baseColor", e.target.value)}
                        helperText={formState.baseColorError}
                        error={formState.baseColorError.length > 0}
                        margin="dense"
                        style={{ marginRight: "2px"}}
                        />
                    <TextField
                        name="stripesColor"
                        value={formState.stripesColor}
                        label="Stripes"
                        onChange={e => handleChange("stripesColor", e.target.value)}
                        helperText={formState.stripesColorError}
                        error={formState.stripesColorError.length > 0}
                        margin="dense"
                        style={{ marginRight: "2px"}}
                        />
                    <TextField
                        name="armsColor"
                        value={formState.armsColor}
                        label="Arms"
                        onChange={e => handleChange("armsColor", e.target.value)}
                        helperText={formState.armsColorError}
                        error={formState.armsColorError.length > 0}
                        margin="dense"
                        style={{ marginRight: "2px"}}
                        />
                    <TextField
                        name="collarColor"
                        value={formState.collarColor}
                        label="Collar"
                        onChange={e => handleChange("collarColor", e.target.value)}
                        helperText={formState.collarColorError}
                        error={formState.collarColorError.length > 0}
                        margin="dense"
                        style={{ marginRight: "2px"}}
                        />
                </Box>
                <Box marginTop={1}>
                    {shirt}
                </Box> 
                {saveErrorBox} 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSave} color="primary" disabled={!saveEnabled}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default TeamDetailDialog;
